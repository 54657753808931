import { ReactSession } from 'react-client-session';
import { init as initFullStory } from '@fullstory/browser';
import { FS_ORG_ID } from '../config';

export const setUserSession = (login) => {
  ReactSession.set('id', login.id);
  ReactSession.set('name', login.name);
  ReactSession.set('accessToken', login.accessToken);
};

export const clearUserSession = () => {
  ReactSession.remove('id');
  ReactSession.remove('name');
  ReactSession.remove('accessToken');
  ReactSession.remove('fullStorySession');
};

export const isAuthenticated = () => {
  const accessToken = ReactSession.get('accessToken');
  return Boolean(accessToken);
};

export const getAuthUser = () => {
  const id = ReactSession.get('id');
  const name = ReactSession.get('name');
  const accessToken = ReactSession.get('accessToken');

  return {
    id,
    name,
    accessToken,
  };
};

export const getAccessToken = () => {
  return ReactSession.get('accessToken');
};

export function runFullStory() {
  const fullStorySession = ReactSession.get('fullStorySession');
  const samplingRate = Math.random() < 0.1;

  // initiate full story if sampling rate is 10% and full story is not yet initiated
  if (samplingRate && !fullStorySession) {
    initFullStory({ orgId: FS_ORG_ID }, ({ sessionUrl }) => {
      ReactSession.set('fullStorySession', sessionUrl);
      // console.log('Full story session initiated... ' + sessionUrl);
    });
  }
}
