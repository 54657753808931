import React from 'react';

import { Button, Image } from 'antd';
import { Container, ContentWrapper, Wrapper } from './styles';
import { Text } from '../../components';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

export default function Banner({ heading, description, imgUrl }) {
  const { fireButtonClickEvent } = useAnalytics({
    pageLabel: PageLabels.HOME_SCREEN,
  });

  const navigate = useNavigate();

  const handleButton = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.LEARN_MORE,
      screen_name: PageLabels.HOME_SCREEN,
    });
    return navigate('/how-to-redeem');
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <Text
            text={heading}
            size="font"
            color="primary"
            fontWeight="bold"
            lineHeight="level3"
          />
          <Text
            text={description}
            size="small"
            color="primary"
            fontWeight="regular"
            lineHeight="level2"
            className="description-text"
          />
          <Button type="link" icon={<RightOutlined />} onClick={handleButton}>
            Learn more
          </Button>
        </ContentWrapper>
        <div>
          <Image width={60} src={imgUrl} preview={false} />
        </div>
      </Wrapper>
    </Container>
  );
}
