import styled, { css } from 'styled-components';
import { Button } from 'antd';

import { FONTS, SIZES, LINE_HEIGHT, COLORS } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BodyWrapper = styled.div`
  ${commonFlex}
  overflow: hidden;
  height: 100%;

  .instructions {
    text-align: center;
  }
`;

export const LocationWrapper = styled.div`
  margin-bottom: -24px;
  margin-top: -6px;

  .fa-location-dot {
    margin-right: 6px;
  }
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  border-bottom: 1px solid #eff0f6;
  gap: 16px;

  .chat-wrapper .ant-image {
    position: absolute;
  }
`;

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  svg {
    fill: ${COLORS.facebook};
    width: ${SIZES.medium}px;
    height: ${SIZES.medium}px;
  }

  h1 {
    text-align: center;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const SearchResults = styled.div`
  ${commonFlex}
  overflow: auto;
  flex: 1;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const StyledButton = styled(Button)`
  color: #5cead9;
  font-weight: ${FONTS.medium};
  font-size: ${SIZES.small}px;
  line-height: ${LINE_HEIGHT.level2}px;

  .material-symbols-outlined {
    font-size: 14px !important;
    margin-right: 6px;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  &.ant-btn-link:hover,
  &.ant-btn-link:focus {
    color: #5cead9;
    opacity: 0.5;
  }
`;

export const EmptyState = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  margin: 18px 16px;
  height: 100%;

  .wrapper {
    margin: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const Column = styled.div`
  flex: 1;
`;

export const TextWrapper = styled.div`
  ${commonFlex}
  width: 100%;
  text-align: center;
`;
