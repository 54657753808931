import React from 'react';
import { Image } from 'antd';
import { FBLoginButton, FBLoginContainer, Text } from '../../components';
import { assets } from '../../app-theme/constants';
import {
  BottomContainer,
  LandingImage,
  Logo,
  TopContainer,
  StyledLogoImg,
  Promo,
} from './styles';
import { FBM_URI } from '../../config';

const FBLoginError = () => {
  const redirectToFbLogin = () => {
    window.location.href = `${FBM_URI}auth/facebook`;
  }

  return (
    <FBLoginContainer>
      <Logo>
        <StyledLogoImg src={assets.growsariLogo} preview={false} />
      </Logo>
      <TopContainer>
        <LandingImage>
          <Image src={assets.FBLoginFailed} preview={false} />
        </LandingImage>
        <Promo>
          <Text
            text="Oops, may issue sa pag-link"
            size="large"
            color="red"
            fontWeight="bold"
            lineHeight="level5"
          />
          <Text
            text="Hindi namin ma-link ang inyong account. Maaaring subukan mag-link ulit."
            size="font"
            color="primary"
            fontWeight="regular"
            lineHeight="level3"
          />
        </Promo>
      </TopContainer>
      <BottomContainer>
        <FBLoginButton
          type="primary"
          text="Link My page"
          handleClick={redirectToFbLogin}
        />
      </BottomContainer>
    </FBLoginContainer>
  );
};

export default FBLoginError;
