import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { API_URI } from './config';
import { getAccessToken } from './utils/session';

const httpLink = new HttpLink({ uri: API_URI });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const accessToken = getAccessToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(!!accessToken && {
        Authorization: accessToken,
      }),
    },
  }));

  return forward(operation);
});

const cache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const client = new ApolloClient({
  // link: from([authMiddleware, errorLink, timeoutLink, httpLink]),
  link: authMiddleware.concat(httpLink),
  cache,
  defaultOptions,
});

export default client;
