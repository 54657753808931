import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Loading,
  GradientHeader,
  Text,
  Voucher,
} from '../../../components';
import {
  BodyWrapper,
  EmptyState,
  VouchersWrapper,
  VoucherWrapper,
} from './styles';
import axios from 'axios';
import { FBM_URI } from '../../../config';
import { Image } from 'antd';
import {
  assets,
  LOGO_SIZE,
  SUKI_COUPON_STATUS,
} from '../../../app-theme/constants';

import { useQuery } from '@apollo/client';
import { LIST_SUKI_COUPONS } from '../../../gql/gql_queries';

import { ButtonTypes, PageLabels } from '../../../app-theme/constants';
import { useAnalytics } from '../../../app-theme';

const formatDate = (date) => {
  if (date) {
    date = date.split('T')[0];
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
};

export const Empty = ({ text }) => {
  return (
    <EmptyState>
      <Image
        width={LOGO_SIZE.homeScreenLogo}
        src={assets.emptyState}
        preview={false}
      />
      <Text
        text={text}
        size="font"
        color="tertiary"
        fontWeight="regular"
        lineHeight="level3"
      />
    </EmptyState>
  );
};

const FBSelectCoupons = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.FB_SELECT_COUPONS,
  });

  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState([]);
  const [vouchersSelected, setVouchersSelected] = useState([]);

  const { state } = useLocation();
  const pageId = state && state.pageId;
  const psid = state && state.psid;
  const account_id = state && state.account_id;

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.FB_SELECT_COUPONS,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
    });
  }, []);

  const { data: availableData, loading: availableLoading } = useQuery(
    LIST_SUKI_COUPONS,
    {
      variables: {
        statuses: [SUKI_COUPON_STATUS.ACTIVE],
        limit: 30,
      },
    }
  );

  useEffect(() => {
    if (availableData) {
      setAvailable(
        availableData.listSukiCoupons.collection.map((item) => item.coupon)
      );
    }
  }, [availableData]);

  useEffect(() => {
    //initialise fb messenger sdk
    window.extAsyncInit = function () {
      window.MessengerExtensions.getUserID(
        function success(uids) {
          console.log('==================', uids.psid);
          // document.getElementById( "psid" ).value = uids.psid;
        },
        function error(err) {
          console.log('Messenger Extension Error: ' + err);
        }
      );
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.com/en_US/messenger.Extensions.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'Messenger');
  }, []);

  const checkAvailabilityButton = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.CHECK_AVAILABILITY,
      screen_name: PageLabels.FB_SELECT_COUPONS,
    });

    var data = JSON.stringify({
      coupons: vouchersSelected,
      psid: psid,
      pageId: pageId,
    });

    var config = {
      method: 'post',
      url: `${FBM_URI}coupons/submit`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    setLoading(true);
    try {
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    //close messenger webview window
    const isSupported = window.MessengerExtensions.isInExtension();
    console.log('Messenger extension, isSupported:', isSupported);

    window.location.href = `https://www.messenger.com/closeWindow/?image_url=https://staging.sariclub.ph/logo192.png&display_text=Closing the window and returning to the thread`;

    window.MessengerExtensions.requestCloseBrowser(
      function success() {
        window.close();
        console.log('Webview closing');
      },
      function error(err) {
        console.log(err);
      }
    );
  };

  const onSelectVoucher = (value, voucher) => {
    if (value) setVouchersSelected((oldArray) => [...oldArray, voucher]);
    else
      setVouchersSelected((current) =>
        current.filter((item) => item !== voucher)
      );
  };

  const body = (
    <>
      {availableLoading || loading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <Text
            text="Which promos are you interested in?"
            size="font"
            color="secondary"
            fontWeight="medium"
            lineHeight="level3"
            className="heading"
          />
          <VouchersWrapper>
            {available.length ? (
              <VoucherWrapper>
                {available.map((item, i) => (
                  <Voucher
                    key={i}
                    imgUrl={item.thumbnail}
                    description={item.sukiTitle}
                    date={formatDate(item.endAt)}
                    selection
                    onSelect={(e) => onSelectVoucher(e.target.checked, item.id)}
                    className="voucher"
                  />
                ))}
              </VoucherWrapper>
            ) : (
              <Empty text="Padating na ang mga promos mo! Abangan mo lang, suki ✨" />
            )}
          </VouchersWrapper>
          {available.length ? (
            <Button
              type="primary"
              text="Check availability"
              disabled={!vouchersSelected.length}
              handleClick={checkAvailabilityButton}
            />
          ) : (
            <></>
          )}
        </BodyWrapper>
      )}
    </>
  );

  return <GradientHeader body={body} bottomStyle={{ overflow: 'hidden' }} />;
};

export default FBSelectCoupons;
