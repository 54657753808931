import styled, { css } from 'styled-components';

const commonFlex = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BodyWrapper = styled.div`
  ${commonFlex}
  overflow: hidden;
  height: 100%;

  .heading {
    text-align: center;
  }

  .ant-btn-primary {
    margin-top: 18px;
  }
`;

export const EmptyState = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 0 16px;
  height: 100%;
`;

export const VouchersWrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding: 2px;

  .voucher {
    border: 1px solid #f6f7f9;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const VoucherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 100%;
`;
