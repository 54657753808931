import React from 'react';
import './notify.css';
import { notification } from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { SIZES, LINE_HEIGHT, FONTS } from '../app-theme/constants';

export const sendNotification = (title, description, type, onClick, key) => {
  let icon = <CloseCircleFilled style={{ color: '#aaaaaa' }} />;
  if (type === 'error' || type === 'failed') {
    icon = <CloseCircleFilled style={{ color: '#bb2124' }} />;
  } else if (type === 'success') {
    icon = <CheckCircleFilled style={{ color: '#007358' }} />;
  }
  notification.open({
    message: title,
    description,
    icon,
    onClick,
    key,
    className: type === 'error' ? 'error' : 'success',
    style: {
      background: type === 'error' ? '#FFF2F1' : '#DFF2EE',
      color: type === 'error' ? '#E81C3A' : '#007358',
      padding: '10px 8px 8px 8px',
      fontWeight: FONTS.semiBold,
      fontSize: SIZES.small,
      lineHeight: `${LINE_HEIGHT.level2}px`,
    },
  });
};

export const closeNotification = (key) => {
  notification.close(key);
};
