import React from 'react';
import { COLORS, FONTS, SIZES, LINE_HEIGHT } from '../../app-theme/constants';

import { StyledText, IconSpan } from './styles';

export default function Text({
  text,
  size,
  color,
  fontWeight,
  lineHeight,
  postIcon,
  className,
}) {
  return (
    <StyledText
      style={{
        color: `${COLORS[color]}`,
        fontSize: `${SIZES[size]}px`,
        fontWeight: `${FONTS[fontWeight]}`,
        lineHeight: `${LINE_HEIGHT[lineHeight]}px`,
      }}
      className={className}
    >
      {text}
      <IconSpan>{postIcon}</IconSpan>
    </StyledText>
  );
}
