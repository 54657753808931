import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { Button, Container, Loading, Text } from '../../components';
import { assets, LOGO_SIZE } from '../../app-theme/constants';
import {
  Content,
  InstructionsContainer,
  QRContainer,
  QRImage,
  QRLabel,
  QROverlap,
  Voucher,
} from './styles';
import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';
import { sendNotification } from '../../utils/notify';

import { GENERATE_VOUCHER } from '../../gql/gql_mutations';
import { useMutation } from '@apollo/client';

export const QRVoucher = ({ isLoading, voucherData }) => {
  const value = () => {
    const qr_string = {
      origin: 'suki',
      promo_code: voucherData.promoCode,
    };
    return JSON.stringify(qr_string);
  };

  return (
    voucherData && (
      <div>
        <Voucher>
          {voucherData.promo && (
            <Image
              src={voucherData.promo.thumbnailUrl}
              fallback="https://cdn-icons-png.flaticon.com/512/7559/7559197.png"
              preview={false}
            />
          )}
          <div className="text-wrapper">
            {voucherData.promo && (
              <Text
                text={
                  voucherData.promo?.title || 'Buy C2 Apple 500mL, get ₱4 off'
                }
                size="small"
                color="primary"
                fontWeight="semiBold"
                lineHeight="level2"
              />
            )}
            {voucherData.payload && (
              <Text
                text={
                  voucherData.payload.endAt
                    ? `Valid until ${formatDate(voucherData.payload.endAt)}`
                    : 'Not available'
                }
                size="base"
                color="secondary"
                fontWeight="medium"
                lineHeight="level1"
              />
            )}
          </div>
        </Voucher>
        <QRImage className="qr-image">
          {isLoading ? (
            <div className="loading">
              <Loading />
            </div>
          ) : (
            <QRCodeSVG
              value={value()}
              size={180}
              bgColor={'#ffffff'}
              fgColor={'#000000'}
              level={'L'}
              includeMargin={false}
              imageSettings={{
                src: assets.logo512,
                x: undefined,
                y: undefined,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
          )}
        </QRImage>
        <QRLabel>
          <Text
            text="Promo code"
            size="font"
            color="primary"
            fontWeight="regular"
            lineHeight="level3"
          />
          <Text
            text={voucherData.promoCode}
            size="font"
            color="primary"
            fontWeight="bold"
            lineHeight="level3"
          />
        </QRLabel>
      </div>
    )
  );
};

const formatDate = (date) => {
  if (date) {
    date = date.split('T')[0];
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
};

const PromoQRLanding = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.PROMO_QR_LANDING,
  });

  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState('');
  const [searchParams] = useSearchParams();

  const [voucherData, setVoucherData] = useState({});

  const [generateVoucher] = useMutation(GENERATE_VOUCHER);
  const [loading, setLoading] = useState(false);

  // generate voucher function
  const handleGenerateVoucher = async (code) => {
    setLoading(true);

    try {
      const { data } = await generateVoucher({
        variables: {
          promoCode: code,
        },
      });

      sessionStorage.setItem('Voucher', JSON.stringify(data.generateVoucher));
      setVoucherData(data.generateVoucher);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      sendNotification(e.message, '', 'error');
    }
  };

  // to get the promo code in url
  useEffect(() => {
    const promoCode = searchParams.get('code').toUpperCase();
    const sessionID = sessionStorage.getItem('SessionID');

    if (promoCode) {
      setPromoCode(promoCode);
    }

    if (!sessionID) {
      handleGenerateVoucher(promoCode);
      getSessionID();
    }

    const voucher = JSON.parse(sessionStorage.getItem('Voucher'));
    if (voucher) {
      setVoucherData(voucher);
    }
  }, []);

  // find stores near me button, redirects to geolocation search
  const findStoresNearMe = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.FIND_STORES_NEAR_ME,
      screen_name: PageLabels.PROMO_QR_LANDING,
    });

    return navigate('/find-stores-near-you', {
      state: { promo_code: promoCode },
    });
  };

  // for session ID generator
  function getSessionID() {
    var sessionID = sessionStorage.getItem('SessionID');
    if (!sessionID) {
      sessionID = generateSessionID();
      sessionStorage.setItem('SessionID', sessionID);

      fireViewScreenEvent({
        screen_name: PageLabels.PROMO_QR_LANDING,
        qr_session_id: sessionStorage.getItem('SessionID'),
      });
    }
    return sessionID;
  }

  function generateSessionID() {
    var timestamp = new Date().getTime();
    var random =
      Math.random().toString(36).substring(2, 6) +
      Math.random().toString(36).substring(2, 6);
    var sessionID = timestamp + random;
    return sessionID;
  }

  return (
    <Container>
      <Content>
        <Image
          width={LOGO_SIZE.gradientLogo}
          src={assets.logoTeal}
          preview={false}
        />
        <div className="voucher-wrapper">
          <Text
            text="Your sari sari store promo:"
            size="medium"
            color="white"
            fontWeight="bold"
            lineHeight="level4"
          />
          <QRContainer className="popover">
            <QROverlap className="overlap">
              <QRVoucher isLoading={loading} voucherData={voucherData} />
            </QROverlap>
          </QRContainer>
        </div>
        <InstructionsContainer>
          <Text
            text="Paano mag-redeem?"
            size="qr_special"
            color="primary"
            fontWeight="bold"
            lineHeight="level4"
          />
          <div className="content">
            <Image src={assets.howTo1} preview={false} />
            <Text
              text="Pumunta at ipakita ang QR code sa participating sari-sari store na malapit sayo!"
              size="font"
              color="secondary"
              fontWeight="regular"
              lineHeight="level3"
            />
          </div>
          <Button
            type="primary"
            text="Find stores near me"
            icon={<ArrowRightOutlined />}
            handleClick={findStoresNearMe}
          />
        </InstructionsContainer>
      </Content>
    </Container>
  );
};

export default PromoQRLanding;
