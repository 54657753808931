import styled from 'styled-components';

import { MISCELLANEOUS } from '../../app-theme/constants';

export const PinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .pincode-input-text {
    height: ${MISCELLANEOUS.pinCode}px !important;
    width: ${MISCELLANEOUS.pinCode}px !important;
  }
`;
