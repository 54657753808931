import styled from 'styled-components';
import { COLORS } from '../../app-theme/constants';

export const LoadingBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;

  background: ${COLORS.white};
  opacity: 0.8;
`;
