import React from 'react';
import { Image } from 'antd';
import { assets } from '../../app-theme/constants';
import { LoadingBackground } from './styles';

export default function Loading({ notRounded }) {
  return (
    <LoadingBackground
      style={notRounded ? { borderRadius: '0' } : { borderRadius: '18px' }}
    >
      <Image width={90} src={assets.globalLoader} preview={false} />
    </LoadingBackground>
  );
}
