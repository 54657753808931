import styled, { css } from 'styled-components';

import { COLORS } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledVoucherContainer = styled.div`
  ${commonFlex};

  gap: 10px;
  padding: 12px;
  box-shadow: 0px 2px 8px rgba(38, 35, 56, 0.05);
  border-radius: 8px;
  background: ${COLORS.white};

  .ant-image-img {
    width: 48px;
  }

  h1 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-checkbox-wrapper {
    margin: 0 6px 0 16px;
  }

  .ant-checkbox-inner:after {
    left: 28.5%;
  }

  .ant-checkbox-checked:after {
    border: 1px solid #008768;
    border-radius: 42px;
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border: 2px solid #c5bfdb;
    border-radius: 50%;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #008768;
    border-color: #008768;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: grid;
    place-items: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  flex: 1;
`;

export const Row = styled.div`
  ${commonFlex};
`;
