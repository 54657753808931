import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Image } from 'antd';
import {
  Banner,
  Button,
  Container,
  Loading,
  Text,
  Voucher,
} from '../../components';
import { assets, SUKI_COUPON_STATUS } from '../../app-theme/constants';
import { ArrowRightOutlined } from '@ant-design/icons';

import {
  BannerWrapper,
  ButtonContainer,
  EmptyState,
  HomeContainer,
  StyledTabs,
  TextWrapper,
  VoucherWrapper,
} from './styles';
import { useNavigate, useLocation } from 'react-router-dom';

import { LIST_SUKI_COUPONS } from '../../gql/gql_queries';

import { ButtonTypes, PageLabels, LOGO_SIZE } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const formatDate = (date) => {
  if (date) {
    date = date.split('T')[0];
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
};

export const Empty = ({ text }) => {
  return (
    <EmptyState>
      <Image
        width={LOGO_SIZE.homeScreenLogo}
        src={assets.emptyState}
        preview={false}
      />
      <Text
        text={text}
        size="font"
        color="tertiary"
        fontWeight="regular"
        lineHeight="level3"
      />
    </EmptyState>
  );
};

export const Tabs = ({
  availableData,
  redeemedData,
  setCurrentTab,
  currentTab,
}) => {
  const { fireButtonClickEvent } = useAnalytics({
    pageLabel: PageLabels.HOME_SCREEN,
  });

  const availableDataCount = () => {
    return (
      <div className="badge">
        Available <span>{availableData.length}</span>
      </div>
    );
  };

  const handleTabClick = (activeKey) => {
    setCurrentTab(activeKey);

    if (activeKey === '1') {
      fireButtonClickEvent({
        button_name: ButtonTypes.AVAILABLE,
        screen_name: PageLabels.HOME_SCREEN,
      });
    } else {
      fireButtonClickEvent({
        button_name: ButtonTypes.REDEEMED,
        screen_name: PageLabels.HOME_SCREEN,
      });
    }
  };

  const availableTabContent = (
    <>
      {availableData.length ? (
        <VoucherWrapper>
          {availableData.map((item, i) => (
            <Voucher
              key={i}
              imgUrl={item.thumbnail}
              description={item.sukiTitle}
              date={formatDate(item.endAt)}
            />
          ))}
        </VoucherWrapper>
      ) : (
        <Empty text="Padating na ang mga promos mo! Abangan mo lang, suki ✨" />
      )}
    </>
  );

  const redeemedTabContent = (
    <>
      {redeemedData.length ? (
        <VoucherWrapper>
          {redeemedData.map((item, i) => (
            <Voucher
              key={i}
              imgUrl={item.coupon.thumbnail}
              description={item.coupon.sukiTitle}
              date={formatDate(item.redeemedAt)}
              redeemed
            />
          ))}
        </VoucherWrapper>
      ) : (
        <Empty text="Wala ka pang na-redeem na promos. Mag-redeem ka na today! ✨" />
      )}
    </>
  );

  const items = [
    { label: availableDataCount(), key: '1', children: availableTabContent }, // remember to pass the key prop
    { label: 'Redeemed', key: '2', children: redeemedTabContent },
  ];

  return (
    <StyledTabs
      defaultActiveKey="1"
      onTabClick={handleTabClick}
      style={{
        marginBottom:
          redeemedData.length > 0 && currentTab === '1' ? '20px' : '70px',
      }}
      items={items}
    />
  );
};

const HomeScreen = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.HOME_SCREEN,
  });

  const navigate = useNavigate();
  const [available, setAvailable] = useState([]);
  const [redeemed, setRedeemed] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');

  const { state } = useLocation();
  const account_id = state && state.account_id;

  const { data: availableData, loading: availableLoading } = useQuery(
    LIST_SUKI_COUPONS,
    {
      variables: {
        statuses: [SUKI_COUPON_STATUS.ACTIVE],
        limit: 30,
      },
    }
  );

  const { data: redeemedData } = useQuery(LIST_SUKI_COUPONS, {
    variables: {
      statuses: [SUKI_COUPON_STATUS.REDEEMED],
      limit: 30,
    },
  });

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.HOME_SCREEN,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
      no_of_coupons: available.length,
    });
  }, [available.length]);

  useEffect(() => {
    if (availableData) {
      setAvailable(
        availableData.listSukiCoupons.collection.map((item) => item.coupon)
      );
    }

    if (redeemedData) {
      setRedeemed(redeemedData.listSukiCoupons.collection.map((item) => item));
    }
  }, [availableData, redeemedData]);

  const handleHomeButton = () => {
    if (!redeemed.length) {
      fireButtonClickEvent({
        button_name: ButtonTypes.HOW_TO_REDEEM,
        screen_name: PageLabels.HOME_SCREEN,
      });
      return navigate('/how-to-redeem', {
        state: { account_id: account_id },
      });
    }

    fireButtonClickEvent({
      button_name: ButtonTypes.FIND_STORES_NEAR_ME,
      screen_name: PageLabels.HOME_SCREEN,
    });

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        return navigate('/find-stores-near-you', {
          state: { account_id: account_id },
        });
      } else if (result.state === 'denied') {
        return navigate('/store-details', {
          state: { account_id: account_id },
        });
      }
    });
  };

  return (
    <Container>
      {availableLoading ? (
        <Loading notRounded />
      ) : (
        <HomeContainer>
          <Image
            width={150}
            src={assets.logoAllTeal}
            preview={false}
            className="to-hide"
          />
          <TextWrapper>
            <Text
              text="Ito ang mga promos mo! 🎁"
              size="medium"
              color="primary"
              fontWeight="bold"
              lineHeight="level4"
            />
            <Text
              text="Mag-redeem na sa participating stores"
              size="small"
              color="secondary"
              fontWeight="regular"
              lineHeight="level2"
            />
          </TextWrapper>
          {available && redeemed && (
            <Tabs
              availableData={available}
              redeemedData={redeemed}
              setCurrentTab={setCurrentTab}
              currentTab={currentTab}
            />
          )}
          {redeemed && redeemed.length && currentTab === '1' ? (
            <BannerWrapper>
              <Banner
                heading="Paano mag-redeem?"
                description="Bumili sa sari-sari store para makakuha ng promos!"
                imgUrl={assets.bannerIcon}
              />
            </BannerWrapper>
          ) : (
            <div></div>
          )}
          <ButtonContainer>
            <Button
              type="primary"
              text={!redeemed.length ? 'How to redeem' : 'Find stores near me'}
              icon={<ArrowRightOutlined />}
              handleClick={handleHomeButton}
            />

            {!redeemed.length && (
              <TextWrapper>
                <Button
                  type="link"
                  text={
                    <Text
                      text="Need help? Contact us"
                      size="small"
                      color="lightGreen"
                      fontWeight="semiBold"
                      lineHeight="level2"
                    />
                  }
                  handleClick={() => window.open('https://m.me/sariclubph')}
                />
              </TextWrapper>
            )}
          </ButtonContainer>
        </HomeContainer>
      )}
    </Container>
  );
};

export default HomeScreen;
