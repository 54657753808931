import { Button } from 'antd';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, SIZES, BUTTON_SIZE } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

export const StyledButton = styled(Button)`
  padding: 16px;
  height: ${BUTTON_SIZE.bottomFullWidth}px;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: ${SIZES.font}px;
  font-weight: ${FONTS.bold};

  &.ant-btn.left {
    flex-direction: row;
  }

  &.ant-btn.right {
    flex-direction: row-reverse;
  }

  &.ant-btn.ant-btn-primary {
    ${commonFlex}
    background: ${COLORS.accent};
    border-color: ${COLORS.accent};
    color: ${COLORS.white};
  }

  &.ant-btn.ant-btn-primary.accent {
    background: ${COLORS.growsariAccent};
    border-color: ${COLORS.growsariAccent};
  }

  &.ant-btn-primary:hover,
  &.ant-btn-primary:focus {
    opacity: 0.95;
  }

  &.ant-btn-default {
    ${commonFlex}
    background: ${COLORS.white};
    border: 1.4px solid ${COLORS.accent};
    color: ${COLORS.accent};
  }

  &.ant-btn-default:hover,
  &.ant-btn-default:focus {
    background: rgba(62, 180, 166, 0.4);
  }

  &.ant-btn-text {
    color: ${COLORS.accent};
  }

  &.ant-btn-link {
    ${commonFlex}
    color: ${COLORS.disabled};
    font-size: ${SIZES.small}px;
    font-weight: ${FONTS.medium};
    height: 100%;
    padding: 0;
  }

  &.ant-btn[disabled] {
    opacity: 0.5;
  }
`;
