import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 20px;
  height: 100%;

  .voucher-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }
`;

export const InstructionsContainer = styled.div`
  background: #fff;
  border-radius: 24px 24px 0 0;
  padding: 16px 24px;
  width: 100%;
  margin-top: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .content img {
    width: 64px;
    height: 64px;
  }
`;

export const QRContainer = styled.div`
  border-radius: 8px;
  -webkit-mask-image: radial-gradient(
    circle 10px at 0,
    transparent 0,
    transparent 20px,
    black 21px
  );
`;

export const QROverlap = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  -webkit-mask-image: radial-gradient(
    circle 10px at 100%,
    transparent 0,
    transparent 20px,
    black 21px
  );
  min-width: 248px;
  position: relative;
`;

export const Voucher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-right: 12px;

  .text-wrapper h1 {
    max-width: 150px;
  }

  .ant-image {
    width: 64px;
    display: grid;
    place-items: center;
  }

  .ant-image-error {
    width: 40px;
  }
`;

export const QRImage = styled.div`
  display: grid;
  place-items: center;
  margin: 1.2rem 0.5rem;

  .loading {
    height: 180px;
  }

  .loading div {
    opacity: 1;
  }
`;

export const QRLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
