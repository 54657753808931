import styled from 'styled-components';
import { Modal } from 'antd';
import { COLORS, FONTS, SIZES } from '../../app-theme/constants';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
  }

  .ant-modal-body .ant-image {
    margin-bottom: 8px;
  }

  .ant-modal-footer {
    padding-bottom: 18px;
    padding-top: 0;
    border-top: none;
  }

  .ant-modal-footer .ant-btn {
    width: 100%;
    height: 40px;
    background: ${COLORS.growsariAccent};
    border-radius: 8px;
    border: 1px solid ${COLORS.growsariAccent};
  }

  .ant-modal-footer .ant-btn:hover {
    opacity: 0.7;
  }

  .ant-modal-footer .ant-btn span {
    color: ${COLORS.white};
    font-size: ${SIZES.font}px;
    font-weight: ${FONTS.semiBold};
  }
`;
