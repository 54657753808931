import React from 'react';
import { Checkbox, Image } from 'antd';
import { Text } from '../../components';
import { Row, Column, StyledVoucherContainer } from './styles';

export default function Voucher({
  imgUrl,
  landing,
  description,
  date,
  redeemed,
  className,
  selection,
  onSelect,
  partnerLogo,
}) {
  return (
    <StyledVoucherContainer className={className}>
      <Image width={48} height={48} src={imgUrl} preview={false} />
      <Column>
        <Text
          text={description}
          size={landing ? 'font' : 'small'}
          color="primary"
          fontWeight="semiBold"
          lineHeight="level2"
        />
        {landing ? (
          <Row>
            <Text
              text="Sponsored by"
              size="base"
              color="secondary"
              fontWeight="regular"
              lineHeight="level1"
            />
            <Image width={55} src={partnerLogo} preview={false} />
          </Row>
        ) : (
          <Text
            text={redeemed ? `Redeemed on ${date}` : `Until ${date}`}
            size="base"
            color="secondary"
            fontWeight="regular"
            lineHeight="level1"
          />
        )}
      </Column>
      {selection && <Checkbox onChange={onSelect} />}
    </StyledVoucherContainer>
  );
}
