import React from 'react';
import { DesktopContainer, MobileContainer } from './styles';

export default function Container({ children, fbm }) {
  return (
    <DesktopContainer>
      <MobileContainer
        className={fbm ? 'fbm-mobile-container' : ''}
        style={{ position: 'relative' }}
      >
        {children}
      </MobileContainer>
    </DesktopContainer>
  );
}
