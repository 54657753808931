import {
  DIMENSIONS,
  COLORS,
  SIZES,
  FONTS,
  LINE_HEIGHT,
  LOGO_SIZE,
  MISCELLANEOUS,
  PADDING,
  GAP,
  BUTTON_SIZE,
} from './theme';
import assets from './assets';
import strings from './strings';
import {
  AnalyticsEventsTypes,
  ButtonTypes,
  PageLabels,
  PopupLabels,
} from './analytics-events-types';

export {
  DIMENSIONS,
  COLORS,
  SIZES,
  GAP,
  MISCELLANEOUS,
  FONTS,
  LINE_HEIGHT,
  LOGO_SIZE,
  PADDING,
  BUTTON_SIZE,
  assets,
  strings,
  AnalyticsEventsTypes,
  ButtonTypes,
  PageLabels,
  PopupLabels,
};

export const SUKI_COUPON_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REDEEMED: 'REDEEMED',
  REDEMPTION_PENDING: 'REDEMPTION_PENDING',
};
