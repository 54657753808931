import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Loading, Input, GradientHeader } from '../../../components';
import { strings } from '../../../app-theme/constants';
import { BodyWrapper } from './styles';
import { sendNotification } from '../../../utils/notify';

import { GENERATE_OTP } from '../../../gql/gql_mutations';
import { useMutation } from '@apollo/client';

import { ButtonTypes, PageLabels } from '../../../app-theme/constants';
import { useAnalytics } from '../../../app-theme';

import '../styles.css';

const FBSignUp = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.FB_SIGN_UP,
  });

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [isLoading, setLoading] = useState(false);

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const pageId = state && state.pageId;
  const psid = state && state.psid;

  const [generateOTP] = useMutation(GENERATE_OTP);

  useEffect(() => {
    if (!state) return navigate('/fbm');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.FB_SIGN_UP,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
    });
  }, []);

  const handleNameInput = (event) => {
    setName(event.target.value);
  };

  const containsSpecialChars = (str) => {
    const specialChars = strings.specialChars;
    return specialChars.test(str);
  };

  const handleSignUp = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.SIGN_UP,
      screen_name: PageLabels.FB_SIGN_UP,
    });

    if (containsSpecialChars(name)) {
      sendNotification(
        'Name cannot contain special characters or numbers',
        '',
        'error'
      );
      return;
    }

    await requestOTP();
  };

  const requestOTP = async () => {
    if (!name) return;

    setLoading(true);

    try {
      await generateOTP({
        variables: { mobile: mobileNumber, type: 'REGISTER' },
      });

      setLoading(false);
      return navigate('/signup-confirmation/fbm', {
        state: { mobileNumber, name, pageId, psid },
      });
    } catch (e) {
      setLoading(false);
      sendNotification(e.message, '', 'error');
    }
  };

  const body = (
    <>
      <BodyWrapper>
        <Input
          label="Mobile number"
          placeholder="Enter 11-digit number"
          value={mobileNumber}
          defaultValue={mobileNumber}
        />
        <Input
          label="Name"
          placeholder="Enter your name"
          autoFocus
          handleUserInput={handleNameInput}
        />
        <Button type="primary" text="Sign up" handleClick={handleSignUp} />
        {isLoading ? <Loading /> : <></>}
      </BodyWrapper>
    </>
  );

  return (
    <GradientHeader
      heading="Welcome! 🥳"
      description="Gumawa na ng account para makakuha ng up to ₱200+ sa promos!"
      body={body}
      fbm
    />
  );
};

export default FBSignUp;
