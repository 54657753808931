import React, { useEffect, useState } from 'react';
import { Button, GradientHeader, Loading } from '../../components';
import { BodyWrapper, ImageWrapper } from './styles';
import { useAnalytics } from '../../app-theme';
import { assets, ButtonTypes, PageLabels } from '../../app-theme/constants';
import { Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { GET_STORES_BY_GEOLOCATION } from '../../gql/gql_queries';

const LocationSearch = () => {
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const account_id = state && state.account_id;
  const promo_code = state && state.promo_code;

  const { fireViewScreenEvent, fireButtonClickEvent } = useAnalytics({
    pageLabel: PageLabels.LOCATION_SEARCH,
  });

  useEffect(() => {
    const sessionFlag = sessionStorage.getItem('SessionFlag');
    if (!sessionFlag) {
      fireViewScreenEvent({
        screen_name: PageLabels.LOCATION_SEARCH,
        account_id: account_id,
        qr_session_id: sessionStorage.getItem('SessionID'),
      });
      sessionStorage.setItem('SessionFlag', true);
    }
  }, []);

  const mapPinIcon = <FontAwesomeIcon icon={faMapPin} />;

  const successCallback = (position) => {
    setLongitude(position.coords.longitude);
    setLatitude(position.coords.latitude);
    setLoading(false);
  };

  const { data: locationData, loading: locationLoading } = useQuery(
    GET_STORES_BY_GEOLOCATION,
    {
      variables: { longitude: longitude, latitude: latitude },
      skip: longitude === '' || latitude === '',
    }
  );

  useEffect(() => {
    if (locationData && !locationLoading) {
      const stores = locationData.getNearbyStoresByLocation.collection;
      return navigate('/store-results', {
        state: {
          stores,
          promo_code,
        },
      });
    }
  }, [locationData]);

  const errorCallback = () => {
    setLoading(false);

    return navigate('/store-details', {
      state: { account_id: account_id, promo_code },
    });
  };

  function report(state) {
    console.log(`Permission ${state}`);
  }

  const handleLocationButton = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.USE_CURRENT_LOCATION,
      screen_name: PageLabels.LOCATION_SEARCH,
    });

    setLoading(true);

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        report(result.state);

        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback,
          {
            enableHighAccuracy: true,
            timeout: 50000,
            maximumAge: 0,
          }
        );
        setLoading(true);
      } else if (result.state === 'denied') {
        report(result.state);
        setLoading(false);
        return navigate('/store-details', {
          state: { account_id: account_id, promo_code },
        });
      }
      result.addEventListener('change', () => {
        report(result.state);
      });
    });
  };

  const handleBarangayButton = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.ENTER_BARANGAY,
      screen_name: PageLabels.LOCATION_SEARCH,
    });

    return navigate('/store-details', { state: { promo_code } });
  };

  const body = (
    <>
      {loading || locationLoading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <ImageWrapper>
            <Image src={assets.location} preview={false} />
          </ImageWrapper>
          <Button
            type="primary"
            icon={mapPinIcon}
            iconPosition="left"
            text="Use current location"
            handleClick={handleLocationButton}
          />
          <Button
            type="text"
            text="Enter barangay"
            handleClick={handleBarangayButton}
          />
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading="Turn on location access to find stores near you"
      body={body}
    />
  );
};

export default LocationSearch;
