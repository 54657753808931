import React from 'react';
import { Button, Image } from 'antd';
import { StyledModal } from './styles';
import { Text } from '../../components';

export default function Toast({
  title,
  text,
  buttonLabel,
  open,
  handleButton,
  buttonLoading,
  imgUrl,
}) {
  return (
    <StyledModal
      open={open}
      centered
      closable={false}
      onOk={handleButton}
      footer={[
        <Button key="submit" onClick={handleButton} loading={buttonLoading}>
          {buttonLabel}
        </Button>,
      ]}
    >
      <Image width={144} src={imgUrl} preview={false} />
      <Text
        text={title}
        size="medium"
        color="primary"
        fontWeight="bold"
        lineHeight="level4"
      />
      <Text
        text={text}
        size="font"
        color="secondary"
        fontWeight="regular"
        lineHeight="level3"
      />
    </StyledModal>
  );
}
