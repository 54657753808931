import styled, { css } from 'styled-components';

import { COLORS, PADDING } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TopContainer = styled.div`
  ${commonFlex}
  justify-content: space-around;
  text-align: center;
  position: relative;
  // display: table;
  padding: 16px 24px;

  .heading {
    width: 350px;
    text-align: center;
  }
`;

export const BottomContainer = styled.div`
  background: ${COLORS.white};
  flex: 1;
  border-radius: 24px 24px 0 0;
  padding: ${PADDING.bottomContainer}px;
  position: relative;
`;

export const TextWrapper = styled.div`
  ${commonFlex}
  margin-top: 12px;
  gap: 8px;
  width: 100%;
`;
