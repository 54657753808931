import styled, { css } from 'styled-components';
import { Select } from 'antd';

import {
  COLORS,
  SIZES,
  FONTS,
  LINE_HEIGHT,
  BUTTON_SIZE,
} from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  align-items: center;
`;

export const StyledDropdown = styled(Select)`
  font-weight: ${FONTS.regular};
  font-size: ${SIZES.font}px;
  color: ${COLORS.primary};
  line-height: ${LINE_HEIGHT.level2}px;
  border-radius: 16px;
  margin-bottom: 8px;

  .ant-select-selector {
    border-radius: 16px !important;
    padding: 0 16px !important;
  }

  .ant-select-selector,
  input {
    height: ${BUTTON_SIZE.bottomFullWidth}px !important;
  }

  .ant-select-selection-placeholder {
    ${commonFlex}
    height: ${BUTTON_SIZE.bottomFullWidth}px;
    font-size: ${SIZES.font}px;
    color: ${COLORS.tertiary};
    font-weight: ${FONTS.regular};
    line-height: ${LINE_HEIGHT.level3}px;
  }

  .ant-select-selection-item {
    ${commonFlex}
  }
`;

export const StyledDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.disabled {
    opacity: 0.7;
  }
`;
