import styled from 'styled-components';
import { Typography } from 'antd';
const { Title } = Typography;

export const StyledText = styled(Title)`
  margin: 0;

  &.ant-typography {
    margin: 0 !important;
  }
`;

export const IconSpan = styled.span`
  margin-left: 4px;
`;
