import React from 'react';
import { StyledFBLoginButton, StyledFBIcon } from './styles';
import { assets } from '../../app-theme/constants';

export default function FBLoginButton({
  text,
  icon,
  size,
  shape,
  type,
  disabled,
  handleClick,
}) {
  return (
    <StyledFBLoginButton
      type={type}
      icon={icon}
      size={size}
      shape={shape}
      block
      disabled={disabled}
      onClick={handleClick}
    >
      <StyledFBIcon src={assets.fbIcon} />
      {text}
    </StyledFBLoginButton>
  );
}
