import React, { useEffect, useState } from 'react';

import { Button, Text } from '../../components';
import { QRVoucher } from '../../pages/PromoQRLanding';
import {
  BottomPopover,
  TitleWrapper,
  TopPopover,
  TransparentBG,
  QRPopover,
} from './styles';
import { CloseOutlined } from '@ant-design/icons';

import { PageLabels, PopupLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const Popover = ({ handlePopover }) => {
  const [voucherData, setVoucherData] = useState({});

  const { fireViewPopupEvent } = useAnalytics({
    pageLabel: PageLabels.STORE_RESULTS,
  });

  useEffect(() => {
    fireViewPopupEvent({
      screen_name: PageLabels.STORE_RESULTS,
      popup_name: PopupLabels.PROMO_QR,
    });

    const voucher = JSON.parse(sessionStorage.getItem('Voucher'));
    if (voucher) {
      setVoucherData(voucher);
    }
  }, []);

  return (
    <TransparentBG>
      <TopPopover onClick={() => handlePopover(false)} />
      <BottomPopover className="animate__animated animate__slideInUp">
        <TitleWrapper>
          <Button
            type="text"
            icon={<CloseOutlined />}
            shape="circle"
            size="large"
            handleClick={() => handlePopover(false)}
          />
          <Text
            text="Your promo"
            size="qr_special"
            color="primary"
            fontWeight="semiBold"
            lineHeight="level4"
          />
        </TitleWrapper>
        <Text
          text="Ipakita ang promo QR code na ito sa sari-sari store para ma-redeem"
          size="small"
          color="primary"
          fontWeight="regular"
          lineHeight="level2"
          className="instructions"
        />
        <QRPopover>
          <QRVoucher voucherData={voucherData} />
          <div className="rip_left" />
          <div className="rip_right" />
        </QRPopover>
      </BottomPopover>
    </TransparentBG>
  );
};

export default Popover;
