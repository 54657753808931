import React from 'react';
import { Image } from 'antd';
import { FBLoginContainer, Text } from '../../components';
import { assets } from '../../app-theme/constants';
import {
  BottomContainer,
  LandingImage,
  Logo,
  TopContainer,
  StyledLogoImg,
  Promo,
} from './styles';
import { useParams } from 'react-router-dom';

const FBLoginSuccess = () => {
  const { pageId } = useParams();
  const pageIdText = `Page ID: ${pageId}`;
  return (
    <FBLoginContainer>
      <Logo>
        <StyledLogoImg src={assets.growsariLogo} preview={false} />
      </Logo>
      <TopContainer>
        <LandingImage>
          <Image src={assets.FBLoginSuccess} preview={false} />
        </LandingImage>
        <Promo>
          <Text
            text="Success ang pag-link"
            size="large"
            color="lightGreen"
            fontWeight="bold"
            lineHeight="level5"
          />
          <Text
            text="Nai-link na namin ang iyong Facebook page"
            size="font"
            color="primary"
            fontWeight="regular"
            lineHeight="level3"
          />
        </Promo>
      </TopContainer>
      <BottomContainer>
        <Text
          text={pageIdText}
          size="medium"
          color="lightGreen"
          fontWeight="bold"
          lineHeight="level5"
        />
      </BottomContainer>
    </FBLoginContainer>
  );
};

export default FBLoginSuccess;
