import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Image } from 'antd';
import {
  Button,
  Container,
  Input,
  Loading,
  Text,
  Toast,
  Voucher,
} from '../../components';
import { assets, LOGO_SIZE } from '../../app-theme/constants';
import {
  ArrowGuide,
  Banner,
  BottomContainer,
  LandingImage,
  Logo,
  PartnerWrapper,
  Promo,
  TopContainer,
  SocialWrapper,
  StepsWrapper,
  StepWrapper,
  TextWrapper,
} from './styles';
import { sendNotification } from '../../utils/notify';

import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_COUPON, GET_SUKI } from '../../gql/gql_queries';
import { isAuthenticated } from '../../utils/session';

import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const Landing = () => {
  const { fireButtonClickEvent, fireViewScreenEvent, fireScrollScreenEvent } =
    useAnalytics({
      pageLabel: PageLabels.LANDING,
    });

  const navigate = useNavigate();
  const [hasPromo, setHasPromo] = useState(true);
  const [mobileNumber, setMobileNumber] = useState('');
  const [toastModal, setToastModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [scrollRef, setScrollRef] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [scrolledHalf, setScrolledHalf] = useState(false);

  const [referralId, setReferralId] = useState('');

  const [searchParams] = useSearchParams();

  const [getSuki, { data, loading, error }] = useLazyQuery(GET_SUKI, {
    fetchPolicy: 'network-only',
  });

  const { data: couponData, loading: couponLoading } = useQuery(GET_COUPON, {
    variables: { code: window.location.pathname.replace('/', '') },
  });

  const steps = [
    {
      image: assets.howTo1,
      description: 'Gumawa ng account para makita lahat ng promos niyo!',
    },
    {
      image: assets.howTo2,
      description:
        'Pumunta sa participating store at ibigay ang iyong mobile number',
    },
    {
      image: assets.howTo4V2,
      description: 'Kunin ang promo items at discounts!',
    },
  ];

  useEffect(() => {
    if (isAuthenticated()) return navigate('/home');

    if (couponData) {
      setHasPromo(true);
    } else {
      setHasPromo(false);
    }
  }, [couponData]);

  useEffect(() => {
    const referralId = searchParams.get('id');
    if (!couponLoading && !couponData) {
      if (referralId) {
        setReferralId(referralId);
      } else {
        return navigate('/');
      }
    }
  });

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.LANDING,
    });
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if (!data.getSuki)
        return navigate('/sign-up', { state: { mobileNumber, referralId } });
      return navigate('/login', {
        state: {
          mobileNumber,
          name: data.getSuki.name.split(' ')[0],
          account_id: data.getSuki.id,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (error && error.message) {
      setToastModal(true);
      setToastMessage(error.message);
    }
  }, [error]);

  const onMobileNumberChange = useCallback((event) => {
    setMobileNumber(event.target.value);
  }, []);

  const checkExistingAccount = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.GET_STARTED,
      screen_name: PageLabels.LANDING,
    });

    if (mobileNumber && mobileNumber.length === 11) {
      getSuki({ variables: { mobile: mobileNumber } });
    } else {
      sendNotification('Please input a valid mobile number', '', 'error');
    }
  };

  const handleToastButton = () => {
    setToastModal(false);
    window.location.href = 'https://gz12p.app.link/sariclubhome';
  };

  /*
    Code below is to determine when user has scrolled the first time and halfway
  */

  useEffect(() => {
    if (scrollRef) {
      scrollRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef) {
        scrollRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollRef]);

  const handleScroll = () => {
    const winHeight = scrollRef.clientHeight;
    const docHeight = scrollRef.scrollHeight;
    const scrollTop = scrollRef.scrollTop;
    const trackLength = docHeight - winHeight;
    const scrollPercentage = Math.round((scrollTop / trackLength) * 100);

    setScrolled(true);

    if (scrollPercentage === 50) {
      setScrolledHalf(true);
    }
  };

  useEffect(() => {
    if (scrolled)
      fireScrollScreenEvent({
        screen_name: PageLabels.LANDING,
        referral_code: referralId,
        scroll_depth_percentage: '1',
      });
  }, [scrolled]);

  useEffect(() => {
    if (scrolledHalf)
      fireScrollScreenEvent({
        screen_name: PageLabels.LANDING,
        referral_code: referralId,
        scroll_depth_percentage: '50',
      });
  }, [scrolledHalf]);

  // -----------------------------------------------------------------

  return (
    <Container>
      {couponLoading || loading ? (
        <Loading notRounded />
      ) : (
        <>
          <TopContainer ref={(el) => setScrollRef(el)}>
            <Logo>
              <Image
                width={LOGO_SIZE.mainLogo}
                src={assets.logoTeal}
                preview={false}
              />
              <div>
                <Text
                  text="Get ₱2,000 worth of discounts sa iyong suking sari-sari store! 🎉"
                  size="large"
                  color="white"
                  fontWeight="bold"
                  lineHeight="level5"
                />
                <Text
                  text="Sali na sa 40,000+ sukis na nakatipid gamit ang SariClub Promos!"
                  size="font"
                  color="white"
                  fontWeight="regular"
                  lineHeight="level3"
                />
              </div>
            </Logo>
            {hasPromo && couponData.getCoupon ? (
              <Promo>
                <Text
                  text="Meron kang"
                  size="font"
                  color="white"
                  fontWeight="medium"
                  lineHeight="level3"
                />
                <Voucher
                  imgUrl={couponData && couponData.getCoupon.thumbnail}
                  description={couponData && couponData.getCoupon.sukiTitle}
                  partnerLogo={couponData && couponData.getCoupon.partner.icon}
                  landing
                />
                <Text
                  text="at iba pa!"
                  size="font"
                  color="white"
                  fontWeight="medium"
                  lineHeight="level3"
                />
              </Promo>
            ) : (
              <LandingImage>
                <Image
                  src={assets.landingV2}
                  preview={false}
                  alt="landing image"
                />
              </LandingImage>
            )}
            <ArrowGuide>
              <Image src={assets.arrow} preview={false} alt="growsari arrow" />
              <Text
                text="Scroll to see how it works"
                size="base"
                color="white"
                fontWeight="bold"
                lineHeight="level3"
              />
            </ArrowGuide>
            <Text
              text="Redeem at 80,000 sari-sari stores nationwide! 🇵🇭"
              size="medium"
              color="white"
              fontWeight="bold"
              lineHeight="level4"
            />
            <StepsWrapper>
              {steps.map((item, index) => {
                return (
                  <StepWrapper key={index}>
                    <Image src={item.image} preview={false} />
                    <div>
                      <Text
                        text={`STEP ${index + 1}`}
                        size="small"
                        color="lightGreen"
                        fontWeight="semiBold"
                        lineHeight="level2"
                      />
                      <Text
                        text={item.description}
                        size="font"
                        color="primary"
                        fontWeight="regular"
                        lineHeight="level3"
                      />
                    </div>
                  </StepWrapper>
                );
              })}
            </StepsWrapper>
            <Text
              text="Ano ang SariClub?"
              size="medium"
              color="white"
              fontWeight="bold"
              lineHeight="level4"
            />
            <Banner>
              <Image
                src={assets.landingBanner}
                preview={false}
                alt="landing banner"
              />
            </Banner>
            <TextWrapper>
              <Text
                text="Ang SariClub Promos ay isang programa na namimigay ng discount vouchers sa mga loyal sukis na namimili sa mga sari-sari stores na umo-order ng kanilang paninda sa GrowSari."
                size="font"
                color="white"
                fontWeight="regular"
                lineHeight="level3"
              />
            </TextWrapper>
            <SocialWrapper
              href="https://www.facebook.com/SariClubPH"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={assets.fbIcon} preview={false} alt="fb icon" />
              <Text
                text="Follow us on Facebook!"
                size="small"
                color="white"
                fontWeight="semiBold"
                lineHeight="level3"
              />
            </SocialWrapper>
            <PartnerWrapper>
              <Text
                text="In partnership with"
                size="base"
                color="tertiary"
                fontWeight="semiBold"
                lineHeight="level3"
              />
              <Image
                src={assets.logoTagline}
                width={72}
                preview={false}
                alt="fb icon"
              />
            </PartnerWrapper>
          </TopContainer>
          <BottomContainer>
            <Input
              pattern="\d*"
              label="Enter mobile number to get promos"
              placeholder="Enter 11-digit number"
              handleUserInput={onMobileNumberChange}
              value={mobileNumber}
              maxLength={11}
              type="number"
            />
            <Button
              type="primary"
              color="accent"
              text="See your promos now!"
              handleClick={checkExistingAccount}
            />
          </BottomContainer>
          <Toast
            title={toastMessage}
            text="Ang SariClub Promos ay para lamang sa mga suki para makakuha ng discounts at iba pang promos mula sa iyong tindahan!"
            buttonLabel="Learn more"
            open={toastModal}
            handleButton={handleToastButton}
            imgUrl={assets.toastError}
          />
        </>
      )}
    </Container>
  );
};

export default Landing;
