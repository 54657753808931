import React, { useState } from 'react';
import './styles.css';
import { Image } from 'antd';
import { assets } from '../../app-theme/constants';
import { Text } from '../../components';
import { StyledOTPInput, TextWrapper } from './styles';
import { sendNotification } from '../../utils/notify';
import OtpTimer from 'otp-timer';

import { GENERATE_OTP } from '../../gql/gql_mutations';
import { useMutation } from '@apollo/client';

export default function OTP({ value, onChange, mobileNumber, type }) {
  const [isLoading, setLoading] = useState(false);
  const [generateOTP] = useMutation(GENERATE_OTP);

  const resendOTP = async () => {
    setLoading(true);
    try {
      await generateOTP({
        variables: { mobile: mobileNumber, type: type },
      });

      sendNotification('OTP Generation Successful', '', 'success');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      sendNotification('OTP Generation Failed', '', 'error');
    }
  };

  return (
    <>
      <StyledOTPInput
        value={value}
        onChange={onChange}
        numInputs={6}
        separator={false}
        shouldAutoFocus={true}
        containerStyle="otp-container"
        inputStyle="otp-input"
        focusStyle="otp-focus"
        disabledStyle="otp-disabled"
        errorStyle="otp-error"
        isInputNum
        autoFocus
      />
      <TextWrapper>
        <Text
          text="Walang natanggap na OTP?"
          size="small"
          color="tertiary"
          fontWeight="medium"
          lineHeight="level2"
        />
        {isLoading ? (
          <Image width={20} src={assets.globalLoader} preview={false} />
        ) : (
          <OtpTimer
            seconds={59}
            minutes={2}
            text="Resend in"
            ButtonText="Resend >"
            resend={resendOTP}
          />
        )}
      </TextWrapper>
    </>
  );
}
