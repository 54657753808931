import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, GradientHeader, Loading, Pin } from '../../../components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BodyWrapper } from './styles';
import { sendNotification } from '../../../utils/notify';

import { REGISTER_ACCOUNT } from '../../../gql/gql_mutations';
import { useMutation } from '@apollo/client';
import { LOGIN_ACCOUNT } from '../../../gql/gql_mutations';
import { setUserSession } from '../../../utils/session';

import { ButtonTypes, PageLabels } from '../../../app-theme/constants';
import { useAnalytics } from '../../../app-theme';

const FBPasscodeCreation = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.FB_PASSCODE_CREATION,
  });

  const navigate = useNavigate();
  const [passcode, setPasscode] = useState('');
  const childFunc = useRef(null);
  const [isLoading, setLoading] = useState(false);

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const name = state && state.name;
  const pageId = state && state.pageId;
  const psid = state && state.psid;

  const [register] = useMutation(REGISTER_ACCOUNT);
  const [login] = useMutation(LOGIN_ACCOUNT);

  useEffect(() => {
    if (!state || sessionStorage.__react_session__) return navigate('/fbm');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.FB_PASSCODE_CREATION,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
    });
  }, []);

  const areSameDigits = () => {
    for (let i = 1; i < passcode.length; i++) {
      if (passcode[i] !== passcode[0]) return false;
    }
    return true;
  };

  const areConsecutiveDigits = () => {
    const first = parseInt(passcode[0]);
    const second = parseInt(passcode[1]);
    const third = parseInt(passcode[2]);
    const last = parseInt(passcode[3]);
    if (first + 1 === second && second + 1 === third && third + 1 === last)
      return true;
    return false;
  };

  const handleCreateAccount = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.CREATE_ACCOUNT,
      screen_name: PageLabels.FB_PASSCODE_CREATION,
    });

    if (passcode === undefined || passcode.length < 4) {
      childFunc.current();
      return sendNotification('Invalid passcode', '', 'error');
    }

    if (areSameDigits()) {
      childFunc.current();
      return sendNotification(
        'Passcode cannot be the same four digits. Please create a new one.',
        '',
        'error'
      );
    }

    if (areConsecutiveDigits()) {
      childFunc.current();
      return sendNotification(
        'Passcode cannot be consecutive numbers. Please create a new one.',
        '',
        'error'
      );
    }

    setLoading(true);

    try {
      await register({
        variables: { name: name, mobile: mobileNumber, passcode: passcode },
      });

      const { data } = await login({
        variables: { mobile: mobileNumber, passcode },
      });

      setUserSession(data.login);
      setLoading(false);
      return navigate('/fbm-promos', {
        state: { pageId, psid, account_id: data.login.id },
      });
    } catch (e) {
      setLoading(false);
      sendNotification(e.message, '', 'error');
    }
  };

  const onPasscodeChange = useCallback((value) => {
    setPasscode(value);
  }, []);

  const body = (
    <>
      <BodyWrapper>
        <Pin onChange={onPasscodeChange} childFunc={childFunc} />
        <Button
          type="primary"
          text="Create account"
          handleClick={handleCreateAccount}
          icon={<ArrowRightOutlined />}
        />
        {isLoading ? <Loading /> : <></>}
      </BodyWrapper>
    </>
  );

  return (
    <GradientHeader
      heading="Maglagay ng passcode📱"
      description="Tandaan mo ‘to dahil gagamitin mo ‘to sa pag-redeem ng mga promos! "
      body={body}
      fbm
    />
  );
};

export default FBPasscodeCreation;
