import styled from 'styled-components';

import { COLORS, FONTS, LINE_HEIGHT, SIZES } from '../../app-theme/constants';

export const TCWrapper = styled.div`
  color: ${COLORS.primary};
  font-size: ${SIZES.small}px;
  line-height: ${LINE_HEIGHT.level2}px;

  ul {
    list-style-type: disc;
  }
`;

export const NumOL = styled.ol`
  padding-inline-start: 12px;

  li {
    margin-top: 12px;
  }
`;

export const RomanOL = styled.ol`
  padding-inline-start: 60px;
  counter-reset: list;

  li {
    padding-left: 12px;
    list-style: none;
    position: relative;
  }

  li:before {
    counter-increment: list;
    content: counter(list, upper-roman) '.) ';
    position: absolute;
    left: -1.4em;
  }
`;

export const NumLI = styled.li`
  font-weight: ${FONTS.semiBold};
  margin-top: 24px !important;
  margin-bottom: 8px;
`;

export const CircleUL = styled.ul`
  list-style-type: circle !important;
  padding-inline-start: 32px;
  margin-top: 4px;

  li {
    margin-top: 12px;
  }
`;

export const SubTitle = styled.p`
  margin-left: -16px;

  :not(:first-child) {
    margin-top: 24px;
  }
`;

export const UBold = styled.span`
  font-weight: ${FONTS.bold};
  text-decoration: underline;
`;

export const Bold = styled.span`
  font-weight: ${FONTS.bold};
`;
