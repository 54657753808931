import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Loading, GradientHeader, Pin } from '../../../components';
import { BodyWrapper } from './styles';
import { sendNotification } from '../../../utils/notify';
import { setUserSession } from '../../../utils/session';

import { useMutation } from '@apollo/client';
import { LOGIN_ACCOUNT } from '../../../gql/gql_mutations';

import { ButtonTypes, PageLabels } from '../../../app-theme/constants';
import { useAnalytics } from '../../../app-theme';

const FBInputPasscode = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.FB_PASSCODE_INPUT,
  });

  const [passcode, setPasscode] = useState();
  const [isLoading, setLoading] = useState(false);
  const childFunc = useRef(null);
  const navigate = useNavigate();

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const pageId = state && state.pageId;
  const psid = state && state.psid;
  const account_id = state && state.account_id;

  const [login] = useMutation(LOGIN_ACCOUNT);

  useEffect(() => {
    if (!state || sessionStorage.__react_session__) return navigate('/fb');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.FB_PASSCODE_INPUT,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
    });
  }, []);

  const handleAccountLogIn = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.LOGIN,
      screen_name: PageLabels.FB_PASSCODE_INPUT,
    });

    if (!passcode) {
      sendNotification('Invalid passcode', '', 'error');
      return;
    }

    if (passcode.length < 4) {
      childFunc.current();
      sendNotification('Invalid passcode', '', 'error');
      return;
    }

    setLoading(true);

    try {
      const { data } = await login({
        variables: { mobile: mobileNumber, passcode: passcode },
      });

      setUserSession(data.login);

      setLoading(false);
      return navigate('/fbm-promos', {
        state: { pageId: pageId, psid: psid, account_id: account_id },
      });
    } catch (e) {
      setLoading(false);
      childFunc.current();
      if (e.message === 'Invalid mobile number or passcode.')
        sendNotification('Incorrect passcode', '', 'error');
      else sendNotification(e.message, '', 'error');
    }
  };

  const checkPasscode = useCallback((value) => {
    setPasscode(value);
  }, []);

  const body = (
    <>
      <BodyWrapper>
        <Pin onChange={checkPasscode} childFunc={childFunc} />
        <Button type="primary" text="Log In" handleClick={handleAccountLogIn} />
        {isLoading ? <Loading /> : <></>}
      </BodyWrapper>
    </>
  );

  return (
    <GradientHeader
      heading="Ilagay ang passcode📱"
      description="Ito ang passcode ng account niyo sa SariClub promos. Wag mag-alala, secure ito at hindi malalaman ng tindahan"
      body={body}
      fbm
    />
  );
};

export default FBInputPasscode;
