import { gql } from '@apollo/client';

export const GENERATE_OTP = gql`
  mutation GenerateOTP($mobile: String!, $type: OTPType!) {
    generateOTP(mobile: $mobile, type: $type)
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($mobile: String!, $code: String!, $type: OTPType!) {
    verifyOTP(mobile: $mobile, code: $code, type: $type)
  }
`;

export const UPDATE_PASSCODE = gql`
  mutation UpdatePasscode($mobile: String!, $passcode: String!) {
    updatePasscode(mobile: $mobile, passcode: $passcode) {
      id
      name
    }
  }
`;

export const REGISTER_ACCOUNT = gql`
  mutation RegisterAccount(
    $name: String!
    $mobile: String!
    $passcode: String!
    $referralCode: String
  ) {
    register(
      name: $name
      mobile: $mobile
      passcode: $passcode
      referralCode: $referralCode
    ) {
      accessToken
    }
  }
`;

export const LOGIN_ACCOUNT = gql`
  mutation LoginAccount($mobile: String!, $passcode: String!) {
    login(mobile: $mobile, passcode: $passcode) {
      accessToken
      id
      name
    }
  }
`;

export const GENERATE_VOUCHER = gql`
  mutation GenerateVoucher($promoCode: String!) {
    generateVoucher(promoCode: $promoCode) {
      promoCode
      promo {
        name
        code
        title
        thumbnailUrl
      }
      code
      payload {
        endAt
      }
    }
  }
`;
