import styled, { css } from 'styled-components';
import { Tabs } from 'antd';

import {
  COLORS,
  LINE_HEIGHT,
  SIZES,
  MISCELLANEOUS,
} from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const HomeContainer = styled.div`
  ${commonFlex}
  align-items: center;
  background: linear-gradient(180deg, ${COLORS.white} 0%, #eff0f6 100%);
  height: 100%;
  padding: ${MISCELLANEOUS.homeScreenPadding}px 0;
  overflow: hidden;

  @media only screen and (max-height: 598px) {
    .to-hide {
      display: none;
    }
  }
`;

export const TextWrapper = styled.div`
  ${commonFlex}
  width: 100%;
  margin-top: 12px;
  gap: 8px;
  padding: 0 24px;
`;

export const StyledTabs = styled(Tabs)`
  margin-top: 14px;
  width: 100%;
  overflow: auto;
  flex: 1;
  padding: 0 24px;

  .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }

  .ant-tabs-content-holder {
    overflow: auto;
    height: 100%;
    ::-webkit-scrollbar {
      width: 0;
    }
  }

  .ant-tabs-tab {
    height: 32px;

    .badge span {
      background: ${COLORS.tertiary};
      border-radius: 12px;
      padding: 2px 8px;
      color: ${COLORS.white};
      margin-left: 4px;
    }
  }

  .ant-tabs-tab-active {
    background: #dcf2f1;
    padding: 0px 12px;
    color: #00786c;
    border-radius: 100px;

    .badge span {
      background: ${COLORS.accent};
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 400;
    font-size: ${SIZES.small}px !important;
    line-height: ${LINE_HEIGHT.level2}px;
    color: #6e7191;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00786c !important;
    font-size: 13px;
  }

  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
`;

export const VoucherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
`;

export const EmptyState = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 0 16px;
  height: 100%;
`;

export const BannerWrapper = styled.div`
  margin-bottom: 54px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  background: ${COLORS.white};
  padding: 10px 24px 12px 24px;
  width: 100%;
`;
