import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import { assets } from '../../app-theme/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import {
  ArrowRightOutlined,
  SearchOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { Button, Input, Loading, GradientHeader, Text } from '../../components';
import {
  BodyWrapper,
  ChatWrapper,
  Column,
  EmptyState,
  LocationWrapper,
  ResultWrapper,
  SearchResults,
  StyledButton,
  TextWrapper,
} from './styles';
import axios from 'axios';
import { useQuery } from '@apollo/client';
import { GET_NEARBY_STORES } from '../../gql/gql_queries';

import {
  AnalyticsEventsTypes,
  ButtonTypes,
  PageLabels,
} from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

export const SearchResult = ({
  storeName,
  storeAddress,
  storeId,
  fbmActivated,
  fbmUrl,
}) => {
  const [loading, setLoading] = useState(false);

  const getPageId = () => {
    var data = JSON.stringify({
      storeIds: [storeId],
    });

    var config = {
      method: 'post',
      url: `${fbmUrl}page/get-page-ids`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        let url = `https://m.me/${response.data[0].pageId}`;
        return (window.location.href = url);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <ResultWrapper>
      <Column>
        <Text
          text={storeName}
          size="font"
          color="primary"
          fontWeight="bold"
          lineHeight="level3"
        />
        <Text
          text={storeAddress}
          size="small"
          color="secondary"
          fontWeight="regular"
          lineHeight="level2"
        />
      </Column>
      {fbmActivated && (
        <ChatWrapper onClick={getPageId} className="chat-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z" />
          </svg>
          <Text
            text="Chat"
            size="small"
            color="facebook"
            fontWeight="semiBold"
            lineHeight="level1"
          />
          {loading ? (
            <Image width={50} src={assets.globalLoader} preview={false} />
          ) : (
            <></>
          )}
        </ChatWrapper>
      )}
    </ResultWrapper>
  );
};

export const Empty = ({ heading, text }) => {
  return (
    <EmptyState>
      <Image width={80} src={assets.emptyState} preview={false} />
      <div className="wrapper">
        <Text
          text={heading}
          size="large"
          color="primary"
          fontWeight="bold"
          lineHeight="level5"
        />
        <Text
          text={text}
          size="font"
          color="secondary"
          fontWeight="regular"
          lineHeight="level3"
        />
      </div>
    </EmptyState>
  );
};

const StoreResults = () => {
  const { fireEvent, fireButtonClickEvent, fireViewScreenEvent } = useAnalytics(
    {
      pageLabel: PageLabels.STORE_RESULTS,
    }
  );

  const navigate = useNavigate();
  const { state } = useLocation();
  const { location, city, barangay, province } = state;

  const account_id = state && state.account_id;
  const promo_code = state && state.promo_code;
  const stores = state && state.stores;

  const [searchStr, setSearchStr] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [fbmUrl, setFbmUrl] = useState('');

  const [originalData, setOriginalData] = useState([]);

  const [openPopover, setOpenPopover] = useState(false);

  useEffect(() => {
    async function load() {
      let { FBM_URI } = await import('../../config');
      setFbmUrl(FBM_URI);
    }

    load();
  });

  const { data: searchResultsData, loading } = useQuery(GET_NEARBY_STORES, {
    variables: { city: city, barangay: barangay, province: province },
    skip: !city || !barangay || !province,
  });

  useEffect(() => {
    if (searchResultsData && !loading) {
      setSearchResults(searchResultsData.getNearbyStores.collection);
      setOriginalData(searchResultsData.getNearbyStores.collection);
    }
  }, [searchResultsData]);

  useEffect(() => {
    if (stores) {
      setSearchResults(stores);
      setOriginalData(stores);
    }
  }, [stores]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.STORE_RESULTS,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
      no_of_stores: searchResults.length,
    });
  }, [searchResults.length]);

  useEffect(() => {
    if (!originalData.length) {
      return;
    }

    const narrowedResults = originalData.filter((item) => {
      const storeName = item.name.toLowerCase();
      return storeName.includes(searchStr);
    });

    setSearchResults(narrowedResults);
  }, [searchStr]);

  const locationIcon = <FontAwesomeIcon icon={faLocationDot} />;

  const changeLocation = (
    <LocationWrapper>
      {location}
      <StyledButton
        type="link"
        icon={locationIcon}
        onClick={() =>
          navigate('/store-details', {
            state: { account_id: account_id, promo_code },
          })
        }
      >
        {stores ? 'Change location' : 'Change'}
      </StyledButton>
    </LocationWrapper>
  );

  const handleHowToRedeemButton = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.HOW_TO_REDEEM,
      screen_name: PageLabels.STORE_RESULTS,
    });

    return navigate('/how-to-redeem', { state: { account_id: account_id } });
  };

  const handleShowPromoButton = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.SHOW_PROMO,
      screen_name: PageLabels.STORE_RESULTS,
    });

    setOpenPopover(true);
  };

  const handleSearch = (event) => {
    fireEvent(AnalyticsEventsTypes.SEARCH_KEYWORD, {
      search_string: event.target.value,
      screen_name: PageLabels.STORE_RESULTS,
    });

    setSearchStr(event.target.value.toLowerCase());
  };

  const handleClickForGAEvent = () => {
    fireEvent(AnalyticsEventsTypes.ENTER_SEARCH, {
      screen_name: PageLabels.STORE_RESULTS,
    });
  };

  const body = (
    <>
      {loading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          {promo_code ? (
            <></>
          ) : (
            <Text
              text="Mag search o magtanong sa malapit na stores kung meron silang SariClub Promos"
              size="small"
              color="darkerAccent"
              fontWeight="semiBold"
              lineHeight="level2"
              className="instructions"
            />
          )}

          <Input
            placeholder="Search"
            icon={<SearchOutlined />}
            handleUserInput={handleSearch}
            onClick={handleClickForGAEvent}
          />
          {searchResults.length ? (
            <>
              <SearchResults>
                {searchResults &&
                  searchResults.map((item, index) => (
                    <SearchResult
                      key={index}
                      storeName={item.name}
                      storeAddress={item.address}
                      storeId={item.id}
                      fbmActivated={item.isFBEnabled}
                      fbmUrl={fbmUrl}
                    />
                  ))}
              </SearchResults>
              {promo_code ? (
                <Button
                  type="primary"
                  text="Show promo"
                  iconPosition="left"
                  icon={<QrcodeOutlined />}
                  handleClick={handleShowPromoButton}
                />
              ) : (
                <Button
                  text="How to redeem"
                  icon={<ArrowRightOutlined />}
                  handleClick={handleHowToRedeemButton}
                />
              )}

              <TextWrapper>
                <Text
                  text="Powered by"
                  size="small"
                  color="tertiary"
                  fontWeight="semiBold"
                  lineHeight="level1"
                  postIcon={
                    <Image
                      src={assets.growsariLogo}
                      preview={false}
                      height={14}
                    />
                  }
                />
              </TextWrapper>
            </>
          ) : (
            <Empty
              heading={
                searchStr.length
                  ? '‘Di mahanap ang store'
                  : 'Walang registered stores na malapit'
              }
              text="Pero wag mag-alala! Pwede tanungin sa iyong paboritong sari-sari store kung meron silang SariClub Promos ✨"
            />
          )}
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading={
        stores
          ? 'Punta na sa store na malapit sayo at ipakita ang promo!'
          : 'Punta na sa store at ipakita ang promo!'
      }
      description={changeLocation}
      body={body}
      bottomStyle={{ overflow: 'hidden' }}
      openPopover={openPopover}
      promoCode={promo_code}
      handlePopover={setOpenPopover}
    />
  );
};

export default StoreResults;
