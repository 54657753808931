import React from 'react';
import { FBLoginDesktopContainer, FBLoginMobileContainer } from './styles';

export default function FBLoginContainer({ children }) {
  return (
    <FBLoginDesktopContainer>
      <FBLoginMobileContainer>{children}</FBLoginMobileContainer>
    </FBLoginDesktopContainer>
  );
}
