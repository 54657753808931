import styled, { css } from 'styled-components';

import { COLORS, GAP } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const BodyWrapper = styled.div`
  ${commonFlex}
  gap: ${GAP.level4}px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    width: auto !important;
    color: ${COLORS.green} !important;
  }
`;
