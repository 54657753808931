import React, { useEffect, useState } from 'react';
import PinInput from 'react-pin-input';
import './styles.css';

import { Button, NumPad } from '../../components';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { PinWrapper } from './styles';

export default function Pin({ onChange, childFunc }) {
  const [mask, setMask] = useState(true);
  const [autoSelect, setAutoSelect] = useState(true);
  const pinRef = React.createRef();

  useEffect(() => {
    childFunc.current = clearPin;
  }, [pinRef]);

  const clearPin = () => {
    const pin = pinRef.current;
    pin.elements[0].state.value = '';
    pin.elements[1].state.value = '';
    pin.elements[2].state.value = '';
    pin.elements[3].state.value = '';
    onChange('');
  };

  const handleMaskToggle = () => {
    setMask(!mask);
  };

  const handlePinInput = (num) => {
    const pin = pinRef.current;
    setAutoSelect(!autoSelect);

    if (num === 'backspace') {
      if (pin.elements[3].state.value) {
        pin.elements[3].state.value = '';
        return;
      }
      if (pin.elements[2].state.value) {
        pin.elements[2].state.value = '';
        return;
      }
      if (pin.elements[1].state.value) {
        pin.elements[1].state.value = '';
        return;
      }
      if (pin.elements[0].state.value) {
        pin.elements[0].state.value = '';
        return;
      }
    }

    if (num !== 'backspace')
      if (pin.elements[3].state.value) return;
      else if (pin.elements[2].state.value) {
        pin.elements[3].state.value = num;
      } else if (pin.elements[1].state.value) {
        pin.elements[2].state.value = num;
      } else if (pin.elements[0].state.value) {
        pin.elements[1].state.value = num;
      } else if (!pin.elements[0].state.value) {
        pin.elements[0].state.value = num;
      }

    let pinString = '';
    pin.elements.forEach((element) => {
      pinString += element.state.value;
    });

    onChange(pinString);
  };

  return (
    <>
      <PinWrapper>
        <PinInput
          length={4}
          ref={pinRef}
          initialValue=""
          secret={mask}
          onChange={onChange}
          type="numeric"
          inputMode="number"
          autoSelect={autoSelect}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          autoFocus
        />
        <Button
          size="large"
          type="link"
          shape="circle"
          icon={mask ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          handleClick={handleMaskToggle}
        />
      </PinWrapper>
      <NumPad handleUserInput={handlePinInput} />
    </>
  );
}
