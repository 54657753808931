import React from 'react';
import { Image } from 'antd';
import { Container, Text, Popover } from '../../components';
import { assets, LOGO_SIZE } from '../../app-theme/constants';
import { BottomContainer, TextWrapper, TopContainer } from './styles';

export default function GradientHeader({
  heading,
  description,
  body,
  bottomStyle,
  fbm,
  openPopover,
  handlePopover,
  promoCode,
}) {
  return (
    <Container fbm={fbm}>
      <TopContainer>
        <Image
          width={LOGO_SIZE.gradientLogo}
          src={assets.logoTeal}
          preview={false}
        />
        {heading || description ? (
          <TextWrapper>
            {heading && (
              <Text
                text={heading}
                size="medium"
                color="white"
                fontWeight="bold"
                lineHeight="level4"
                className="heading"
              />
            )}
            {description && (
              <Text
                text={description}
                size="small"
                color="white"
                fontWeight="regular"
                lineHeight="level2"
              />
            )}
          </TextWrapper>
        ) : (
          <></>
        )}
      </TopContainer>
      <BottomContainer style={bottomStyle}>{body}</BottomContainer>
      {openPopover ? (
        <Popover promoCode={promoCode} handlePopover={handlePopover} />
      ) : (
        <></>
      )}
    </Container>
  );
}
