import styled from 'styled-components';

import { COLORS, FONTS } from '../../../app-theme/constants';

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .terms-text {
    text-align: center;
  }

  .terms-button {
    color: ${COLORS.accent};
    font-weight: ${FONTS.semiBold};
    cursor: pointer;
  }

  .terms-button:hover {
    opacity: 0.8;
  }
`;
