import React, { useEffect } from 'react';
import { GradientHeader, TermsAndConditions, Text } from '../../components';
import { BodyWrapper } from './styles';

import { PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const TermsAndConditionsScreen = () => {
  const { fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.TERMS_AND_CONDITIONS,
  });

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.TERMS_AND_CONDITIONS,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
    });
  }, []);

  const body = (
    <BodyWrapper>
      <Text
        text="TERMS & CONDITIONS FOR USE OF SARICLUB PROMOS"
        size="font"
        color="accent"
        fontWeight="bold"
        lineHeight="level3"
        className="terms-and-conditions-title"
      />
      <TermsAndConditions />
    </BodyWrapper>
  );

  return (
    <GradientHeader
      heading="Terms and Conditions"
      body={body}
      bottomStyle={{ overflow: 'hidden' }}
    />
  );
};

export default TermsAndConditionsScreen;
