import styled, { css } from 'styled-components';

import { COLORS, DIMENSIONS } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  ${commonFlex}
  align-items: center;
  text-align: center;
  flex: 1;
  position: relative;
  overflow: auto;
`;

export const BottomContainer = styled.div`
  ${commonFlex}
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  height: 198px;
  padding: 24px;
  background: ${COLORS.white};
  border-radius: 24px 24px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.maxWidth}px) {
    border-radius: 24px 24px 0 0;
  }

  @media only screen and (min-width: ${DIMENSIONS.maxWidth + 1}px) {
    border-radius: 24px 24px 16px 16px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Logo = styled.div`
  ${commonFlex}
  align-items: center;
  gap: 12px;
  padding: 12px 32px;

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const Promo = styled.div`
  ${commonFlex}
  gap: 8px;
  margin-top: 7px;
  width: 100%;
  padding: 32px;
`;

export const LandingImage = styled.div`
  width: 100%;
  margin-bottom: 4px;

  .ant-image {
    width: 100%;
    padding: 14px;
  }
`;

export const ArrowGuide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;

  box-shadow: 0px 8px 16px rgba(38, 35, 56, 0.1);
  border-radius: 62px;
  background: #008577;

  position: sticky;
  bottom: 2%;

  margin-bottom: 18px;

  .ant-image {
    width: 16px;
  }
`;

export const StepsWrapper = styled.div`
  ${commonFlex}
  gap: 16px;
  background: #dcf2f1;
  border-radius: 16px;
  margin: 15px 24px 24px 24px;
  padding: 16px;
`;

export const StepWrapper = styled.div`
  display: flex;
  gap: 12px;
  text-align: left;
  align-items: center;

  img {
    width: 64px;
  }
`;

export const TextWrapper = styled.div`
  padding: 16px 24px;
`;

export const Banner = styled.div`
  width: 100%;
  margin-top: 16px;

  .ant-image {
    width: 100%;
  }
`;

export const SocialWrapper = styled.a`
  display: flex;
  padding: 9px 16px;
  gap: 4px;
  border: 2px solid #ffffff;
  border-radius: 8px;
`;

export const PartnerWrapper = styled.div`
  display: flex;
  gap: 6px;
  padding: 16px 28px;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 16px 16px 0px 0px;
  margin-top: 28px;
`;
