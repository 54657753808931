import styled, { css } from 'styled-components';

import { GAP } from '../../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const BodyWrapper = styled.div`
  ${commonFlex}
  gap: ${GAP.level4}px;
`;
