import styled from 'styled-components';

export const TransparentBG = styled.div`
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.4);
  position: absolute;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const BottomPopover = styled.div`
  background: #fff;
  border-radius: 32px 32px 0 0;
  width: 100%;
  padding: 24px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  .instructions {
    padding: 0 3rem;
  }

  .popover {
    margin: 1.7rem 2.5rem;
    text-align: left;
  }

  .popover .overlap {
    border: 4px solid #07a69c;
  }
`;

export const TopPopover = styled.div`
  height: 100%;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: 12px;
  width: 100%;

  button {
    position: absolute;
    left: 0;
  }

  button svg {
    color: #4e4b66;
  }
`;

export const QRPopover = styled.div`
  padding: 0.7rem 1rem;
  border: 4px solid #06b6aa;
  border-radius: 8px;
  position: relative;
  width: fit-content;
  margin-top: 1.2rem;

  .text-wrapper {
    text-align: left;
  }

  .rip_left,
  .rip_right {
    position: absolute;
    border-style: solid;
    border-width: 4px;
    border-color: #fff #fff #06b6aa #06b6aa;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    background: #fff;
  }

  .rip_left {
    left: -27px;
    top: 45%;
    transform: rotate(225deg);
  }

  .rip_right {
    right: -27px;
    top: 45%;
    transform: rotate(45deg);
  }

  .qr-image .ant-image {
    margin: 0.2rem 0.7rem;
  }
`;
