import styled, { css } from 'styled-components';

import { LOGO_SIZE } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const BodyWrapper = styled.div`
  ${commonFlex}
  gap: 20px;
  position: relative;
  height: 100%;
`;

export const TextWrapper = styled.div`
  ${commonFlex}
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 5px;

  .ant-image-img {
    width: ${LOGO_SIZE.instructionsIcons}px;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const StepsWrapper = styled.div`
  ${commonFlex}
  height: 100%;
  gap: 24px;
  padding-bottom: 80px;
`;
