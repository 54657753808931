import styled, { css } from 'styled-components';
import { Button } from '../../../components';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const BodyWrapper = styled.div`
  ${commonFlex}
  gap: 20px;
`;

export const StyledButton = styled(Button)`
  width: auto;
`;
