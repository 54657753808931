import React from 'react';
import { isAuthenticated } from './session';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PublicRoute = () => {
  const location = useLocation();
  return !isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: '/home', state: { from: location } }} />
  );
};

export default PublicRoute;
