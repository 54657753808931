export const AnalyticsEventsTypes = {
  VIEW_SCREEN: 'view_screen',
  SCROLL_SCREEN: 'scroll_screen',
  CLICK_BUTTON: 'click_button',
  SELECT_DROPDOWN_OPTION: 'select_dropdown_options',
  ENTER_SEARCH: 'enter_search',
  SEARCH_KEYWORD: 'search_keyword',
  VIEW_POPUP: 'view_popup',
};

export const ButtonTypes = {
  GET_STARTED: 'See your promos now!',
  SIGN_UP: 'Signup',
  SUBMIT: 'Submit',
  CREATE_ACCOUNT: 'Create account',
  LOGIN: 'Login',
  FIND_STORES_NEAR_ME: 'Find stores near me',
  SEARCH: 'Search',
  HOW_TO_REDEEM: 'How to redeem',
  RESET: 'Reset',
  RESET_PASSCODE: 'Reset passcode',
  AVAILABLE: 'Available',
  REDEEMED: 'Redeemed',
  LEARN_MORE: 'Learn more',
  CHECK_AVAILABILITY: 'Check availability',
  USE_CURRENT_LOCATION: 'Use current location',
  SHOW_PROMO: 'Show promo',
  ENTER_BARANGAY: 'Enter barangay',
};

export const PopupLabels = {
  PROMO_QR: 'SariClub_PromoQR',
};

export const PageLabels = {
  LANDING: 'SariClub_GetStarted',
  SIGN_UP: 'SariClub_CreateAccount',
  LOGIN_CONFIRMATION: 'SariClub_OTPVerification',
  PASSCODE_CREATION: 'SariClub_CreatePasscode',
  LOGIN: 'SariClub_Login',
  ACCOUNT_VERIFICATION: 'ResetPasscodeOTP',
  PASSCODE_RESET: 'ResetPasscodeInput',
  HOME_SCREEN: 'SariClub_CouponsHome',
  INSTRUCTIONS_SCREEN: 'SariClub_HowtoRedeem',
  STORE_DETAILS: 'SariClub_FindStores',
  STORE_RESULTS: 'SariClub_NearbyStores',
  TERMS_AND_CONDITIONS: 'SariClub_TermsAndConditions',
  FB_LANDING: 'FBM_InputNumber',
  FB_PASSCODE_INPUT: 'FBM_InputPasscode',
  FB_SIGN_UP: 'FBM_CreateAccount',
  FB_LOGIN_CONFIRMATION: 'FBM_OTPVerification',
  FB_PASSCODE_CREATION: 'FBM_CreatePasscode',
  FB_SELECT_COUPONS: 'FBM_SelectPromos',
  LOCATION_SEARCH: 'SariClub_LocationAccess',
  PROMO_QR_LANDING: 'SariClub_QR',
};
