import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Loading, GradientHeader, OTP } from '../../components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BodyWrapper } from './styles';
import { sendNotification } from '../../utils/notify';

import { useMutation } from '@apollo/client';
import { VERIFY_OTP } from '../../gql/gql_mutations';

import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const AccountVerification = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.ACCOUNT_VERIFICATION,
  });

  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [isLoading, setLoading] = useState(false);

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const account_id = state && state.account_id;

  const [verifyOTP] = useMutation(VERIFY_OTP);

  useEffect(() => {
    if (!state) return navigate('/');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.ACCOUNT_VERIFICATION,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
    });
  }, []);

  const handleOTPNumber = () => {
    if (mobileNumber) {
      let redactedNumber = '';
      for (let i = 0; i < mobileNumber.length; i++) {
        let char = mobileNumber[i];
        if (i > 3 && i < 7) char = '*';
        redactedNumber += char;
      }
      return redactedNumber;
    }
  };

  const verifyOTPInput = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.SUBMIT,
      screen_name: PageLabels.ACCOUNT_VERIFICATION,
    });

    if (!otp) {
      sendNotification('Invalid OTP. Please try again.', '', 'error');
      return;
    }

    if (otp.length < 6) {
      setOtp('');
      sendNotification('Invalid OTP. Please try again.', '', 'error');
      return;
    }

    setLoading(true);

    try {
      await verifyOTP({
        variables: { code: otp, mobile: mobileNumber, type: 'RESET' },
      });

      setLoading(false);

      return navigate('/reset-passcode', {
        state: { mobileNumber, account_id: account_id },
      });
    } catch (e) {
      setLoading(false);
      setOtp('');
      sendNotification(e.message, '', 'error');
    }
  };

  const onOtpChange = useCallback((value) => {
    setOtp(value);
  }, []);

  const body = (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <OTP
            value={otp}
            onChange={onOtpChange}
            mobileNumber={mobileNumber}
            type="RESET"
          />
          <Button
            type="primary"
            text="Submit"
            loading={isLoading}
            handleClick={verifyOTPInput}
            icon={<ArrowRightOutlined />}
          />
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading="I-verify ang iyong account"
      description={`Nagpadala kami ng OTP sa ${handleOTPNumber()}. Code expires in 3 minutes.`}
      body={body}
    />
  );
};

export default AccountVerification;
