import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, GradientHeader, Loading, OTP } from '../../components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BodyWrapper } from './styles';
import { sendNotification } from '../../utils/notify';

import { VERIFY_OTP } from '../../gql/gql_mutations';
import { useMutation } from '@apollo/client';

import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const LoginConfirmation = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.LOGIN_CONFIRMATION,
  });

  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [isLoading, setLoading] = useState(false);

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const referralId = state && state.referralId;
  const name = state && state.name;

  const [verifyOTP] = useMutation(VERIFY_OTP);

  useEffect(() => {
    if (!state) return navigate('/');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.LOGIN_CONFIRMATION,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
    });
  }, []);

  const handleOTPNumber = () => {
    if (mobileNumber) {
      let redactedNumber = '';
      for (let i = 0; i < mobileNumber.length; i++) {
        let char = mobileNumber[i];
        if (i > 3 && i < 7) char = '*';
        redactedNumber += char;
      }
      return redactedNumber;
    }
  };

  const verifyOTPInput = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.SUBMIT,
      screen_name: PageLabels.LOGIN_CONFIRMATION,
    });

    if (!otp) {
      sendNotification('Invalid OTP. Please try again.', '', 'error');
      return;
    }

    if (otp.length < 6) {
      setOtp('');
      sendNotification('Invalid OTP. Please try again.', '', 'error');
      return;
    }

    setLoading(true);

    try {
      await verifyOTP({
        variables: { code: otp, mobile: mobileNumber, type: 'REGISTER' },
      });
      setLoading(false);
      return navigate('/create-passcode', {
        state: { mobileNumber, name, referralId },
      });
    } catch (e) {
      setLoading(false);
      setOtp('');
      sendNotification(e.message, '', 'error');
    }
  };

  const onOtpChange = useCallback((value) => {
    setOtp(value);
  }, []);

  const body = (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          {isLoading ? <Loading /> : <></>}
          <OTP
            value={otp}
            onChange={onOtpChange}
            mobileNumber={mobileNumber}
            type="REGISTER"
          />
          <Button
            type="primary"
            text="Submit"
            loading={isLoading}
            handleClick={verifyOTPInput}
            icon={<ArrowRightOutlined />}
          />
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading="Ikaw ba talaga yan? 🔍"
      description={`Nagpadala kami ng OTP sa ${handleOTPNumber()}. Input mo dito para ma-verify ang iyong number.`}
      body={body}
    />
  );
};

export default LoginConfirmation;
