import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Loading,
  Input,
  GradientHeader,
  TermsAndConditions,
  Text,
} from '../../components';
import { strings } from '../../app-theme/constants';
import { BodyWrapper } from './styles';
import { sendNotification } from '../../utils/notify';

import { GENERATE_OTP } from '../../gql/gql_mutations';
import { useMutation } from '@apollo/client';

import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const SignUp = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.SIGN_UP,
  });

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const referralId = state && state.referralId;

  const [generateOTP] = useMutation(GENERATE_OTP);

  useEffect(() => {
    if (!state) return navigate('/');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.SIGN_UP,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
    });
  }, []);

  const handleNameInput = (event) => {
    setName(event.target.value);
  };

  const containsSpecialChars = (str) => {
    const specialChars = strings.specialChars;
    return specialChars.test(str);
  };

  const handleSignUp = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.SIGN_UP,
      screen_name: PageLabels.SIGN_UP,
    });

    if (containsSpecialChars(name)) {
      sendNotification(
        'Name cannot contain special characters or numbers',
        '',
        'error'
      );
      return;
    }

    await requestOTP();
  };

  const requestOTP = async () => {
    if (!name) return;

    setLoading(true);

    try {
      await generateOTP({
        variables: { mobile: mobileNumber, type: 'REGISTER' },
      });

      setLoading(false);
      return navigate('/confirm-login', {
        state: { mobileNumber, name, referralId },
      });
    } catch (e) {
      setLoading(false);
      sendNotification(e.message, '', 'error');
    }
  };

  const showTermsModal = () => {
    setTermsOpen(true);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };

  const termsText = (
    <>
      Sa paggawa ng account, sumasangayon ka sa aming{' '}
      <span className="terms-button" onClick={showTermsModal}>
        Terms & Conditions
      </span>
    </>
  );

  const termsTitleText = (
    <>
      <Text
        text="TERMS & CONDITIONS FOR USE OF SARICLUB PROMOS"
        size="font"
        color="accent"
        fontWeight="bold"
        lineHeight="level3"
        className="terms-and-conditions-title"
      />
    </>
  );

  const body = (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <Input
            label="Mobile number"
            placeholder="Enter 11-digit number"
            value={mobileNumber}
            defaultValue={mobileNumber}
          />
          <Input
            label="Name"
            placeholder="Enter your name"
            autoFocus
            handleUserInput={handleNameInput}
          />
          <Text
            text={termsText}
            size="small"
            color="secondary"
            fontWeight="regular"
            lineHeight="level2"
            className="terms-text"
          />
          <Modal
            open={termsOpen}
            title={termsTitleText}
            onCancel={handleTermsClose}
            bodyStyle={{ height: 'calc(60vh)', overflow: 'auto' }}
            centered
            footer={[
              <Button
                block
                type="primary"
                text="Close"
                key="close"
                handleClick={handleTermsClose}
              />,
            ]}
          >
            <TermsAndConditions />
          </Modal>
          <Button type="primary" text="Sign up" handleClick={handleSignUp} />
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading="Welcome! 🥳"
      description="Gumawa na ng account para makakuha ng up to ₱200+ sa promos!"
      body={body}
    />
  );
};

export default SignUp;
