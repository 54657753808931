import React from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloClient from './apollo-client';
import { ReactSession } from 'react-client-session';

import './app.css';
import { GOOGLE_ANALYTICS_ID } from './config';

import {
  AccountVerification,
  FBInputPasscode,
  FBLandingScreen,
  FBSelectCoupons,
  HomeScreen,
  InstructionsScreen,
  Landing,
  LocationSearch,
  LogIn,
  TermsAndConditionsScreen,
  LoginConfirmation,
  PasscodeCreation,
  PasscodeReset,
  PromoQRLanding,
  SignUp,
  StoreDetails,
  StoreResults,
  FBLogin,
  FBLoginSuccess,
  FBLoginError,
  FBSignUp,
  FBSignUpConfirmation,
  FBPasscodeCreation,
} from './pages';
import PrivateRoute from './utils/privateRoute';
import PublicRoute from './utils/publicRoute';
import { runFullStory } from './utils/session';

const App = () => {
  ReactSession.setStoreType('sessionStorage');
  runFullStory();

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <HelmetProvider>
          <div id="app" className="App">
            {GOOGLE_ANALYTICS_ID && (
              <Helmet>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
                />
                <script>
                  {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag() {
                    dataLayer.push(arguments)
                  }
                gtag('js', new Date());
                gtag('config', '${GOOGLE_ANALYTICS_ID}');`}
                </script>
              </Helmet>
            )}
            <Link to="/referral?id="></Link>
            <Link to="/promo?code="></Link>
            <Routes>
              <Route path="/verify-account" element={<PublicRoute />}>
                <Route
                  path="/verify-account"
                  element={<AccountVerification />}
                />
              </Route>
              <Route path="/home" element={<PrivateRoute />}>
                <Route path="/home" element={<HomeScreen />} />
              </Route>
              <Route path="/store-results" element={<StoreResults />} />
              <Route path="/how-to-redeem" element={<InstructionsScreen />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/confirm-login" element={<LoginConfirmation />} />
              <Route path="/create-passcode" element={<PasscodeCreation />} />
              <Route path="/reset-passcode" element={<PasscodeReset />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route
                path="/termsandconditions"
                element={<TermsAndConditionsScreen />}
              />

              <Route path="/:pageId/:psid" element={<FBLandingScreen />} />
              <Route path="/login/fbm" element={<FBInputPasscode />} />
              <Route path="/signup/fbm" element={<FBSignUp />} />
              <Route
                path="/signup-confirmation/fbm"
                element={<FBSignUpConfirmation />}
              />
              <Route
                path="/passcode-creation/fbm"
                element={<FBPasscodeCreation />}
              />
              <Route path="/fbm-promos" element={<FBSelectCoupons />} />

              <Route path="/store-details" element={<StoreDetails />} />
              <Route path="/link-fb-business" element={<FBLogin />} />
              <Route
                path="/link-fb-business-success/:pageId"
                element={<FBLoginSuccess />}
              />
              <Route
                path="/link-fb-business-error"
                element={<FBLoginError />}
              />
              <Route
                path="/find-stores-near-you"
                element={<LocationSearch />}
              />
              <Route path="/referral" element={<Landing />} />
              <Route path="/promo" element={<PromoQRLanding />} />
              <Route path="/" element={<Landing />} />
              <Route path="/*" element={<Landing />} />
            </Routes>
          </div>
        </HelmetProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
