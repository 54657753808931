import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { Loading, Button, Input, Text, Toast } from '../../../components';
import {
  assets,
  LOGO_SIZE,
  ButtonTypes,
  PageLabels,
} from '../../../app-theme/constants';
import {
  DesktopContainer,
  MobileContainer,
  BottomContainer,
  Logo,
  TopContainer,
} from './styles';
import { sendNotification } from '../../../utils/notify';
import { useLazyQuery } from '@apollo/client';
import { GET_SUKI } from '../../../gql/gql_queries';
import { useAnalytics } from '../../../app-theme';
import { useParams } from 'react-router-dom';

const FBLandingScreen = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.FB_LANDING,
  });

  const { pageId, psid } = useParams();

  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [toastModal, setToastModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [getSuki, { data, loading, error }] = useLazyQuery(GET_SUKI, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.FB_LANDING,
    });
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if (!data.getSuki)
        return navigate('/signup/fbm', {
          state: { mobileNumber, pageId, psid },
        });
      return navigate('/login/fbm', {
        state: { mobileNumber, pageId, psid, account_id: data.getSuki.id },
      });
    }
  }, [data]);

  useEffect(() => {
    if (error && error.message) {
      setToastModal(true);
      setToastMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (sessionStorage.__react_session__) return navigate('/fbm-promos');
  }, [sessionStorage.__react_session__]);

  const onMobileNumberChange = useCallback((event) => {
    setMobileNumber(event.target.value);
  }, []);

  const checkExistingAccount = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.GET_STARTED,
      screen_name: PageLabels.FB_LANDING,
    });

    if (mobileNumber && mobileNumber.length === 11) {
      getSuki({ variables: { mobile: mobileNumber } });
    } else {
      sendNotification('Please input a valid mobile number', '', 'error');
    }
  };

  const handleToastButton = () => {
    setToastModal(false);
    window.location.href = 'https://gz12p.app.link/sariclubhome';
  };

  return (
    <DesktopContainer>
      <MobileContainer>
        <TopContainer>
          <Logo>
            <Image
              width={LOGO_SIZE.mainLogo}
              src={assets.logoTeal}
              preview={false}
            />
            <Text
              text="May promos ka kapag bumili sa mga sari-sari stores na malapit sayo! 🎉"
              size="large"
              color="white"
              fontWeight="bold"
              lineHeight="level5"
            />
          </Logo>
        </TopContainer>
        <BottomContainer>
          <>
            <Input
              pattern="\d*"
              label="Enter mobile number to get promos"
              placeholder="Enter 11-digit number"
              handleUserInput={onMobileNumberChange}
              value={mobileNumber}
              maxLength={11}
              type="number"
            />
            <Button
              type="primary"
              text="Get started"
              handleClick={checkExistingAccount}
            />
            {loading ? <Loading /> : <></>}
          </>
        </BottomContainer>
        <Toast
          title={toastMessage}
          text="Ang SariClub Promos ay para lamang sa mga suki para makakuha ng discounts at iba pang promos mula sa iyong tindahan!"
          buttonLabel="Learn more"
          open={toastModal}
          handleButton={handleToastButton}
          imgUrl={assets.toastError}
        />
      </MobileContainer>
    </DesktopContainer>
  );
};

export default FBLandingScreen;
