import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, GradientHeader, Loading, Pin } from '../../components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BodyWrapper } from './styles';
import { sendNotification } from '../../utils/notify';

import { useMutation } from '@apollo/client';
import { LOGIN_ACCOUNT, UPDATE_PASSCODE } from '../../gql/gql_mutations';
import { setUserSession } from '../../utils/session';

import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const PasscodeReset = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.PASSCODE_RESET,
  });

  const navigate = useNavigate();
  const [passcode, setPasscode] = useState();
  const childFunc = useRef(null);
  const [isLoading, setLoading] = useState(false);

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const account_id = state && state.account_id;

  const [updatePasscode] = useMutation(UPDATE_PASSCODE);
  const [login] = useMutation(LOGIN_ACCOUNT);

  useEffect(() => {
    if (!state || sessionStorage.__react_session__) return navigate('/');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.PASSCODE_RESET,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
    });
  }, []);

  const areSameDigits = () => {
    for (let i = 1; i < passcode.length; i++) {
      if (passcode[i] !== passcode[0]) return false;
    }
    return true;
  };

  const areConsecutiveDigits = () => {
    const first = parseInt(passcode[0]);
    const second = parseInt(passcode[1]);
    const third = parseInt(passcode[2]);
    const last = parseInt(passcode[3]);
    if (first + 1 === second && second + 1 === third && third + 1 === last)
      return true;
    return false;
  };

  const handlePasscodeChange = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.RESET_PASSCODE,
      screen_name: PageLabels.PASSCODE_RESET,
    });

    if (passcode === undefined || passcode.length < 4) {
      childFunc.current();
      return sendNotification('Invalid passcode', '', 'error');
    }

    if (areSameDigits()) {
      childFunc.current();
      return sendNotification(
        'Passcode cannot be the same four digits. Please create a new one.',
        '',
        'error'
      );
    }

    if (areConsecutiveDigits()) {
      childFunc.current();
      return sendNotification(
        'Passcode cannot be consecutive numbers. Please create a new one.',
        '',
        'error'
      );
    }

    setLoading(true);

    try {
      await updatePasscode({
        variables: { mobile: mobileNumber, passcode: passcode },
      });

      const { data } = await login({
        variables: { mobile: mobileNumber, passcode },
      });

      setUserSession(data.login);

      setLoading(false);
      return navigate('/home', {
        state: { account_id: account_id },
      });
    } catch (e) {
      setLoading(false);
      sendNotification(e.message, '', 'error');
    }
  };

  const onPasscodeReset = useCallback((value) => {
    setPasscode(value);
  }, []);

  const body = (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <Pin onChange={onPasscodeReset} childFunc={childFunc} />
          <Button
            type="primary"
            text="Reset passcode"
            handleClick={handlePasscodeChange}
            icon={<ArrowRightOutlined />}
          />
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading="Reset passcode 📱"
      description="Tandaan mo ‘to dahil gagamitin mo ‘to sa pag-redeem ng mga promos! "
      body={body}
    />
  );
};

export default PasscodeReset;
