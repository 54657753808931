// import React, { useEffect, useState, useCallback } from 'react';
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { FBLoginButton, FBLoginContainer, Text } from '../../components';
import { assets } from '../../app-theme/constants';
import {
  BottomContainer,
  LandingImage,
  Logo,
  TopContainer,
  StyledLogoImg,
  Promo,
} from './styles';
import { FBM_URI } from '../../config';


const FBLogin = () => {
  const redirectToFbLogin = () => {
    window.location.href = `${FBM_URI}auth/facebook`;
  };

  return (
    <FBLoginContainer>
      <Logo>
        <StyledLogoImg src={assets.growsariLogo} preview={false} />
      </Logo>
      <TopContainer>
        <LandingImage>
          <Image src={assets.FBLinkLandingLogo} preview={false} />
        </LandingImage>
        <Promo>
          <Text
            text="I-Link ang FB Business Page mo"
            size="large"
            color="lightGreen"
            fontWeight="bold"
            lineHeight="level5"
          />
          <Text
            text="I-link ang iyong FB Business page upang maging mas madali ang pakikipag-usap sa iyong suki."
            size="font"
            color="primary"
            fontWeight="regular"
            lineHeight="level3"
          />
          <Text
            text="Pindutin ang Link My Page para ma-link ang iyong Facebook Page. "
            size="font"
            color="primary"
            fontWeight="regular"
            lineHeight="level3"
          />
        </Promo>
      </TopContainer>
      <BottomContainer>
        <FBLoginButton
          type="primary"
          text="Link My Page"
          handleClick={redirectToFbLogin}
        />
      </BottomContainer>
    </FBLoginContainer>
  );
};

export default FBLogin;
