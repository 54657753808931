export const API_URI = process.env.REACT_APP_API_URI;
export const LOCALITIES_URI = process.env.REACT_APP_LOCALITIES_URI;
export const FBM_URI = process.env.REACT_APP_FBM_URI;
export const DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE;
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;
export const INFO_KEY = process.env.REACT_APP_INFO_KEY;
export const PHONE_KEY = process.env.REACT_APP_PHONE_KEY;
export const SARICLUB_URI = process.env.REACT_APP_SARICLUB_URI;
export const FS_ORG_ID = process.env.REACT_APP_FS_ORG_ID;
