import { gql } from '@apollo/client';

export const GET_SUKI = gql`
  query getSuki($mobile: String!) {
    getSuki(mobile: $mobile) {
      id
      name
    }
  }
`;

export const GET_NEARBY_STORES = gql`
  query GetNearbyStores(
    $city: String!
    $barangay: String!
    $province: String!
  ) {
    getNearbyStores(city: $city, barangay: $barangay, province: $province) {
      collection {
        name
        address
        storeId
        id
        isFBEnabled
      }
    }
  }
`;

export const GET_COUPON = gql`
  query GetCoupon($code: String!) {
    getCoupon(code: $code) {
      sukiTitle
      thumbnail
      status
      partner {
        code
        icon
      }
    }
  }
`;

export const LIST_SUKI_COUPONS = gql`
  query ListSukiCoupons($statuses: [CouponStatus!]!, $limit: Int) {
    listSukiCoupons(statuses: $statuses, limit: $limit) {
      collection {
        status
        redeemedAt
        coupon {
          endAt
          sukiTitle
          status
          rebateAmount
          thumbnail
          id
        }
      }
    }
  }
`;

export const LIST_LOCALITIES = gql`
  query {
    locality_data {
      barangay_list {
        id
        locality
        city
        province
        region
        country
        is_deleted
        code
        updated_at
        is_serviceable
      }
      city_list {
        city
        province
        region
        country
        is_serviceable
      }
    }
  }
`;

export const GET_STORES_BY_GEOLOCATION = gql`
  query GetNearbyStoresByLocation($longitude: Float!, $latitude: Float!) {
    getNearbyStoresByLocation(longitude: $longitude, latitude: $latitude) {
      collection {
        name
        username
        isSariClubRegistered
        address
      }
    }
  }
`;
