import React from 'react';
import { Select } from 'antd';
import { Text } from '../../components';
import { StyledDropdown, StyledDropdownContainer } from './styles';
const { Option } = Select;

export default function Dropdown({
  label,
  options,
  disabled,
  placeholder,
  onSelect,
  loading,
}) {
  return (
    <StyledDropdownContainer className={disabled ? 'disabled' : ''}>
      <Text
        text={label}
        size="small"
        color="secondary"
        fontWeight="semiBold"
        lineHeight="level2"
      />
      <StyledDropdown
        showSearch
        disabled={disabled}
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={onSelect}
        loading={loading}
      >
        {options &&
          options.map((item, index) => (
            <Option key={index} value={item.value}>
              {item.label}
            </Option>
          ))}
      </StyledDropdown>
    </StyledDropdownContainer>
  );
}
