import styled, { css } from 'styled-components';

import { COLORS, DIMENSIONS } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;


export const Logo = styled.div`
  ${commonFlex}
  align-items: start;
  gap: 36px;
  padding: 24px;
`;

export const StyledLogoImg = styled.img`
    height: 24px;
    vertical-align: center;
    flex: none;
`;


export const TopContainer = styled.div`
  ${commonFlex}
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex: 1;
  gap: 16px;
  position: relative;
`;


export const BottomContainer = styled.div`
  ${commonFlex}
  justify-content: end;
  gap: 12px;
  width: 100%;
  padding: 24px;
  background: ${COLORS.white};
  border-radius: 24px 24px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.maxWidth}px) {
    border-radius: 24px 24px 0 0;
  }

  @media only screen and (min-width: ${DIMENSIONS.maxWidth + 1}px) {
    border-radius: 24px 24px 16px 16px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Promo = styled.div`
  ${commonFlex}
  gap: 8px;
  margin-top: 7px;
  width: 100%;
  padding: 32px;
`;

export const LandingImage = styled.div`
  .ant-image-img {
    width: 100%;
  }

  @media screen and (max-width: 320px) and (max-height: 598px) {
    .ant-image-img {
      display: none;
    }
  }
`;
