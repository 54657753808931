import styled from 'styled-components';
import { COLORS } from '../../app-theme/constants';

export const Container = styled.div`
  background: linear-gradient(
    0deg,
    rgba(48, 202, 193, 0.9),
    rgba(48, 202, 193, 0.9)
  );
  padding: 12px 16px;
  @media only screen and (max-height: 598px) {
    padding: 0;
    background: none;
  }
  background-blend-mode: overlay, normal;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: ${COLORS.white};
  border-radius: 8px;
  padding: 20px 16px 10px 16px;
  gap: 12px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-btn-link {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    padding: 0;
    color: #06b6aa;
    font-weight: 600;

    span {
      margin: 0;
    }
  }
`;
