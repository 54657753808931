import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Loading, GradientHeader, Pin, Text } from '../../components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BodyWrapper, TextWrapper } from './styles';
import { sendNotification } from '../../utils/notify';
import { isAuthenticated, setUserSession } from '../../utils/session';

import { useMutation } from '@apollo/client';
import { LOGIN_ACCOUNT, GENERATE_OTP } from '../../gql/gql_mutations';

import { ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const LogIn = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.LOGIN,
  });

  const [passcode, setPasscode] = useState();
  const [isLoading, setLoading] = useState(false);
  const childFunc = useRef(null);
  const navigate = useNavigate();

  const { state } = useLocation();
  const mobileNumber = state && state.mobileNumber;
  const name = state && state.name;
  const account_id = state && state.account_id;

  const [login] = useMutation(LOGIN_ACCOUNT);
  const [generateOTP] = useMutation(GENERATE_OTP);

  useEffect(() => {
    if (isAuthenticated()) return navigate('/home');

    if (!state) return navigate('/');
  }, [state]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.LOGIN,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
    });
  }, []);

  const handleAccountLogIn = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.LOGIN,
      screen_name: PageLabels.LOGIN,
    });

    if (!passcode) {
      sendNotification('Invalid passcode', '', 'error');
      return;
    }

    if (passcode.length < 4) {
      childFunc.current();
      sendNotification('Invalid passcode', '', 'error');
      return;
    }

    setLoading(true);

    try {
      const { data } = await login({
        variables: { mobile: mobileNumber, passcode: passcode },
      });

      setUserSession(data.login);

      setLoading(false);
      return navigate('/home', {
        state: { account_id: account_id },
      });
    } catch (e) {
      setLoading(false);
      if (e.message === 'Invalid mobile number or passcode.')
        sendNotification('Incorrect passcode', '', 'error');
      else sendNotification(e.message, '', 'error');
    }
  };

  const checkPasscode = useCallback((value) => {
    setPasscode(value);
  }, []);

  const capitalizeFirstLetter = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const resetPasscode = async () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.RESET,
      screen_name: PageLabels.LOGIN,
    });

    setLoading(true);

    try {
      await generateOTP({
        variables: {
          mobile: mobileNumber,
          type: 'RESET',
        },
      });

      setLoading(false);
      return navigate('/verify-account', {
        state: { mobileNumber, account_id: account_id },
      });
    } catch (e) {
      setLoading(false);
      sendNotification(e.message, '', 'error');
    }
  };

  const body = (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <Pin onChange={checkPasscode} childFunc={childFunc} />
          <TextWrapper>
            <Text
              text="Forgot Passcode?"
              size="small"
              color="tertiary"
              fontWeight="medium"
              lineHeight="level2"
            />
            <Button
              type="link"
              text="Reset"
              icon={<ArrowRightOutlined />}
              handleClick={resetPasscode}
            />
          </TextWrapper>
          <Button
            type="primary"
            text="Log In"
            handleClick={handleAccountLogIn}
          />
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading={`Welcome back, ${capitalizeFirstLetter(name)}!`}
      description="Ilagay ang iyong passcode para maka-redeem ng promos!"
      body={body}
    />
  );
};

export default LogIn;
