import styled from 'styled-components';

// import { COLORS, FONTS } from '../../app-theme/constants';

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 100%;
  padding-right: 2px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
`;
