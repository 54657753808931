import styled, { css } from 'styled-components';

import { COLORS, DIMENSIONS } from '../../app-theme/constants';

let viewportHeight = window.innerHeight;

const gradientBackground = css`
  background: radial-gradient(rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  background-blend-mode: overlay, normal;
`;

const defaultShadow = css`
  filter: drop-shadow(0px 8px 16px rgba(38, 35, 56, 0.1));
`;

export const FBLoginDesktopContainer = styled.div`
  height: ${viewportHeight ? `${viewportHeight}px` : '100vh'};
  background: ${COLORS.white};
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.maxWidth}px) {
    display: flex;
  }

  @media only screen and (min-width: ${DIMENSIONS.maxWidth + 1}px) {
    display: grid;
    place-items: center;
  }
`;

export const FBLoginMobileContainer = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: ${DIMENSIONS.maxWidth}px;

  ${gradientBackground}
  ${defaultShadow}

  @media only screen and (max-width: ${DIMENSIONS.maxWidth}px) {
    border-radius: 0;
    height: 100%;
  }

  @media only screen and (min-width: ${DIMENSIONS.maxWidth + 1}px) {
    border-radius: 16px;
    height: 800px;
  }
`;
