import React from 'react';
import {
  Column,
  Row,
  PadWrapper,
  StyledBackspace,
  StyledButton,
} from './styles';

export const NumberButton = ({ number, handleClick }) => {
  return (
    <StyledButton
      type="primary"
      shape="circle"
      size="large"
      onClick={() => handleClick(number)}
    >
      {number}
    </StyledButton>
  );
};

export default function NumPad({ handleUserInput }) {
  return (
    <PadWrapper>
      <Row>
        <NumberButton number="1" handleClick={handleUserInput} />
        <NumberButton number="2" handleClick={handleUserInput} />
        <NumberButton number="3" handleClick={handleUserInput} />
      </Row>
      <Row>
        <NumberButton number="4" handleClick={handleUserInput} />
        <NumberButton number="5" handleClick={handleUserInput} />
        <NumberButton number="6" handleClick={handleUserInput} />
      </Row>
      <Row>
        <NumberButton number="7" handleClick={handleUserInput} />
        <NumberButton number="8" handleClick={handleUserInput} />
        <NumberButton number="9" handleClick={handleUserInput} />
      </Row>
      <Row>
        <Column />
        <NumberButton number="0" handleClick={handleUserInput} />
        <Column>
          <StyledBackspace
            type="primary"
            shape="circle"
            size="large"
            alt=""
            onClick={() => handleUserInput('backspace')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
              <path d="m22.4 31.7 5.6-5.6 5.6 5.6 2.15-2.15L30.1 24l5.55-5.55-2.15-2.15-5.5 5.6-5.6-5.6-2.15 2.15L25.9 24l-5.65 5.55ZM6 24l8.45-11.95q.65-.9 1.55-1.475.9-.575 2-.575h21q1.25 0 2.125.875T42 13v22q0 1.25-.875 2.125T39 38H18q-1.1 0-2-.575-.9-.575-1.55-1.475Zm3.75 0 7.7 11H39V13H17.45ZM39 24V13v22Z" />
            </svg>
          </StyledBackspace>
        </Column>
      </Row>
    </PadWrapper>
  );
}
