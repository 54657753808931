import React, { useEffect } from 'react';
import { Image } from 'antd';
import { Button, GradientHeader, Text } from '../../components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { assets } from '../../app-theme/constants';
import {
  BodyWrapper,
  ButtonContainer,
  StepWrapper,
  StepsWrapper,
  TextWrapper,
} from './styles';
import { useNavigate, useLocation } from 'react-router-dom';

import { ButtonTypes, PageLabels, LOGO_SIZE } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

export const Step = ({ imgUrl, number, description }) => {
  return (
    <StepWrapper>
      <Image width={LOGO_SIZE.instructionsIcons} src={imgUrl} preview={false} />
      <TextWrapper>
        <Text
          text={`STEP ${number}`}
          size="small"
          color="primary"
          fontWeight="semiBold"
          lineHeight="level2"
        />
        <Text
          text={description}
          size="font"
          color="secondary"
          fontWeight="regular"
          lineHeight="level3"
        />
      </TextWrapper>
    </StepWrapper>
  );
};

const InstructionsScreen = () => {
  const { fireButtonClickEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.INSTRUCTIONS_SCREEN,
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const account_id = state && state.account_id;

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.INSTRUCTIONS_SCREEN,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
    });
  }, []);

  const handleButtonClick = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.FIND_STORES_NEAR_ME,
      screen_name: PageLabels.INSTRUCTIONS_SCREEN,
    });

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        return navigate('/find-stores-near-you', {
          state: { account_id: account_id },
        });
      } else if (result.state === 'denied') {
        return navigate('/store-details', {
          state: { account_id: account_id },
        });
      }
    });
  };

  const body = (
    <BodyWrapper>
      <StepsWrapper>
        <Step
          imgUrl={assets.howTo1}
          number="1"
          description="Maghanap ng participating stores malapit sa iyo"
        />
        <Step
          imgUrl={assets.howTo2}
          number="2"
          description="Pumunta sa store at ibigay ang iyong mobile number"
        />
        <Step
          imgUrl={assets.howTo3V2}
          number="3"
          description="Sabihin kung anong promo gusto niyong i-redeem"
        />
        <Step
          imgUrl={assets.howTo4V2}
          number="4"
          description="Kunin ang promo item at discount!"
        />
      </StepsWrapper>
      <ButtonContainer>
        <Button
          type="primary"
          text="Find stores near me"
          handleClick={handleButtonClick}
          icon={<ArrowRightOutlined />}
        />
      </ButtonContainer>
    </BodyWrapper>
  );

  return <GradientHeader heading="Paano mag-redeem? 🤔" body={body} />;
};

export default InstructionsScreen;
