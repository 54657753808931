/* eslint-disable indent */
let viewportWidth = window.innerWidth;

const SCREEN_SIZES = {
  mobileExtraLarge: viewportWidth > 470 && viewportWidth <= 500,
  mobileLarge: viewportWidth > 340 && viewportWidth <= 470,
  mobileMedium: viewportWidth > 310 && viewportWidth <= 340,
  mobileSmall: viewportWidth > 280 && viewportWidth <= 310,
  mobileExtraSmall: viewportWidth <= 280,
};

export const DIMENSIONS = {
  maxWidth: 428,
  maxHeight: 800,
  minHeight: 380,
  bgHeight: 100,
};

export const COLORS = {
  primary: '#262338',
  secondary: '#4E4B66',
  tertiary: '#6E7191',
  disabled: '#C5BFDB',
  white: '#FFF',
  error: '#E81C3A',
  green: '#00786C',
  accent: '#06B6AA',
  growsariAccent: '#ED5A29',
  darkerAccent: '#07857C',
  background: '#F5F5F5',
  border: '#D9DBE9',
  keypad: '#F7F7FC',
  facebook: '#3975EA',
  fbBlue: '#4267B2',
  lightGreen: '#07A69C',
  red: '#F8602D',
  buttonRed: '#ED5A29',
};

export const LINE_HEIGHT = {
  level1: SCREEN_SIZES.mobileExtraLarge
    ? 16
    : SCREEN_SIZES.mobileLarge
    ? 14
    : SCREEN_SIZES.mobileMedium
    ? 12
    : SCREEN_SIZES.mobileSmall
    ? 10
    : SCREEN_SIZES.mobileExtraSmall
    ? 8
    : 16,
  level2: SCREEN_SIZES.mobileExtraLarge
    ? 22
    : SCREEN_SIZES.mobileLarge
    ? 20
    : SCREEN_SIZES.mobileMedium
    ? 18
    : SCREEN_SIZES.mobileSmall
    ? 16
    : SCREEN_SIZES.mobileExtraSmall
    ? 14
    : 22,
  level3: SCREEN_SIZES.mobileExtraLarge
    ? 24
    : SCREEN_SIZES.mobileLarge
    ? 22
    : SCREEN_SIZES.mobileMedium
    ? 20
    : SCREEN_SIZES.mobileSmall
    ? 18
    : SCREEN_SIZES.mobileExtraSmall
    ? 16
    : 24,
  level4: SCREEN_SIZES.mobileExtraLarge
    ? 32
    : SCREEN_SIZES.mobileLarge
    ? 30
    : SCREEN_SIZES.mobileMedium
    ? 28
    : SCREEN_SIZES.mobileSmall
    ? 26
    : SCREEN_SIZES.mobileExtraSmall
    ? 24
    : 32,
  level5: SCREEN_SIZES.mobileExtraLarge
    ? 34
    : SCREEN_SIZES.mobileLarge
    ? 32
    : SCREEN_SIZES.mobileMedium
    ? 30
    : SCREEN_SIZES.mobileSmall
    ? 28
    : SCREEN_SIZES.mobileExtraSmall
    ? 26
    : 34,
};

export const SIZES = {
  base: SCREEN_SIZES.mobileExtraLarge
    ? 11
    : SCREEN_SIZES.mobileLarge
    ? 10
    : SCREEN_SIZES.mobileMedium
    ? 9
    : SCREEN_SIZES.mobileSmall
    ? 8
    : SCREEN_SIZES.mobileExtraSmall
    ? 7
    : 11,
  small: SCREEN_SIZES.mobileExtraLarge
    ? 13
    : SCREEN_SIZES.mobileLarge
    ? 11
    : SCREEN_SIZES.mobileMedium
    ? 10
    : SCREEN_SIZES.mobileSmall
    ? 9
    : SCREEN_SIZES.mobileExtraSmall
    ? 8
    : 13,
  font: SCREEN_SIZES.mobileExtraLarge
    ? 15
    : SCREEN_SIZES.mobileLarge
    ? 13
    : SCREEN_SIZES.mobileMedium
    ? 12
    : SCREEN_SIZES.mobileSmall
    ? 12
    : SCREEN_SIZES.mobileExtraSmall
    ? 11
    : 15,
  medium: SCREEN_SIZES.mobileExtraLarge
    ? 20
    : SCREEN_SIZES.mobileLarge
    ? 18
    : SCREEN_SIZES.mobileMedium
    ? 16
    : SCREEN_SIZES.mobileSmall
    ? 14
    : SCREEN_SIZES.mobileExtraSmall
    ? 13
    : 20,
  large: SCREEN_SIZES.mobileExtraLarge
    ? 24
    : SCREEN_SIZES.mobileLarge
    ? 22
    : SCREEN_SIZES.mobileMedium
    ? 20
    : SCREEN_SIZES.mobileSmall
    ? 18
    : SCREEN_SIZES.mobileExtraSmall
    ? 17
    : 24,
  extraLarge: SCREEN_SIZES.mobileExtraLarge
    ? 28
    : SCREEN_SIZES.mobileLarge
    ? 26
    : SCREEN_SIZES.mobileMedium
    ? 24
    : SCREEN_SIZES.mobileSmall
    ? 22
    : SCREEN_SIZES.mobileExtraSmall
    ? 21
    : 28,
  backspace: SCREEN_SIZES.mobileExtraLarge
    ? 40
    : SCREEN_SIZES.mobileLarge
    ? 36
    : SCREEN_SIZES.mobileMedium
    ? 32
    : SCREEN_SIZES.mobileSmall
    ? 28
    : SCREEN_SIZES.mobileExtraSmall
    ? 24
    : 40,
  qr_special: SCREEN_SIZES.mobileExtraLarge
    ? 17
    : SCREEN_SIZES.mobileLarge
    ? 15
    : SCREEN_SIZES.mobileMedium
    ? 13
    : SCREEN_SIZES.mobileSmall
    ? 13
    : SCREEN_SIZES.mobileExtraSmall
    ? 12
    : 17,
};

export const FONTS = {
  bold: '700',
  semiBold: '600',
  medium: '500',
  regular: '400',
  light: '300',
};

export const PADDING = {
  bottomContainer: SCREEN_SIZES.mobileExtraLarge
    ? 24
    : SCREEN_SIZES.mobileLarge
    ? 22
    : SCREEN_SIZES.mobileMedium
    ? 20
    : SCREEN_SIZES.mobileSmall
    ? 18
    : SCREEN_SIZES.mobileExtraSmall
    ? 16
    : 24,
};

export const GAP = {
  level1: SCREEN_SIZES.mobileExtraLarge
    ? 8
    : SCREEN_SIZES.mobileLarge
    ? 5
    : SCREEN_SIZES.mobileMedium
    ? 4
    : SCREEN_SIZES.mobileSmall
    ? 3
    : SCREEN_SIZES.mobileExtraSmall
    ? 2
    : 8,
  level2: SCREEN_SIZES.mobileExtraLarge
    ? 12
    : SCREEN_SIZES.mobileLarge
    ? 10
    : SCREEN_SIZES.mobileMedium
    ? 8
    : SCREEN_SIZES.mobileSmall
    ? 6
    : SCREEN_SIZES.mobileExtraSmall
    ? 5
    : 12,
  level3: SCREEN_SIZES.mobileExtraLarge
    ? 14
    : SCREEN_SIZES.mobileLarge
    ? 12
    : SCREEN_SIZES.mobileMedium
    ? 10
    : SCREEN_SIZES.mobileSmall
    ? 8
    : SCREEN_SIZES.mobileExtraSmall
    ? 6
    : 14,
  level4: SCREEN_SIZES.mobileExtraLarge
    ? 20
    : SCREEN_SIZES.mobileLarge
    ? 18
    : SCREEN_SIZES.mobileMedium
    ? 16
    : SCREEN_SIZES.mobileSmall
    ? 14
    : SCREEN_SIZES.mobileExtraSmall
    ? 12
    : 20,
  level5: SCREEN_SIZES.mobileExtraLarge
    ? 24
    : SCREEN_SIZES.mobileLarge
    ? 20
    : SCREEN_SIZES.mobileMedium
    ? 18
    : SCREEN_SIZES.mobileSmall
    ? 16
    : SCREEN_SIZES.mobileExtraSmall
    ? 14
    : 24,
  level6: SCREEN_SIZES.mobileExtraLarge
    ? 36
    : SCREEN_SIZES.mobileLarge
    ? 32
    : SCREEN_SIZES.mobileMedium
    ? 28
    : SCREEN_SIZES.mobileSmall
    ? 24
    : SCREEN_SIZES.mobileExtraSmall
    ? 20
    : 36,
  level7: SCREEN_SIZES.mobileExtraLarge
    ? 40
    : SCREEN_SIZES.mobileLarge
    ? 36
    : SCREEN_SIZES.mobileMedium
    ? 32
    : SCREEN_SIZES.mobileSmall
    ? 28
    : SCREEN_SIZES.mobileExtraSmall
    ? 24
    : 40,
};

export const MISCELLANEOUS = {
  pinCode: SCREEN_SIZES.mobileExtraLarge
    ? 48
    : SCREEN_SIZES.mobileLarge
    ? 43
    : SCREEN_SIZES.mobileMedium
    ? 38
    : SCREEN_SIZES.mobileSmall
    ? 33
    : SCREEN_SIZES.mobileExtraSmall
    ? 28
    : 48,
  numPadSize: SCREEN_SIZES.mobileExtraLarge
    ? 64
    : SCREEN_SIZES.mobileLarge
    ? 54
    : SCREEN_SIZES.mobileMedium
    ? 48
    : SCREEN_SIZES.mobileSmall
    ? 44
    : SCREEN_SIZES.mobileExtraSmall
    ? 40
    : 64,
  homeScreenPadding: SCREEN_SIZES.mobileExtraLarge
    ? 24
    : SCREEN_SIZES.mobileLarge
    ? 18
    : SCREEN_SIZES.mobileMedium
    ? 12
    : SCREEN_SIZES.mobileSmall
    ? 6
    : SCREEN_SIZES.mobileExtraSmall
    ? 0
    : 24,
};

export const LOGO_SIZE = {
  mainLogo: SCREEN_SIZES.mobileExtraLarge
    ? 180
    : SCREEN_SIZES.mobileLarge
    ? 160
    : SCREEN_SIZES.mobileMedium
    ? 140
    : SCREEN_SIZES.mobileSmall
    ? 120
    : SCREEN_SIZES.mobileExtraSmall
    ? 100
    : 180,
  gradientLogo: SCREEN_SIZES.mobileExtraLarge
    ? 150
    : SCREEN_SIZES.mobileLarge
    ? 130
    : SCREEN_SIZES.mobileMedium
    ? 110
    : SCREEN_SIZES.mobileSmall
    ? 90
    : SCREEN_SIZES.mobileExtraSmall
    ? 70
    : 150,
  homeScreenLogo: SCREEN_SIZES.mobileExtraLarge
    ? 160
    : SCREEN_SIZES.mobileLarge
    ? 140
    : SCREEN_SIZES.mobileMedium
    ? 120
    : SCREEN_SIZES.mobileSmall
    ? 100
    : SCREEN_SIZES.mobileExtraSmall
    ? 80
    : 160,
  instructionsIcons: SCREEN_SIZES.mobileExtraLarge
    ? 80
    : SCREEN_SIZES.mobileLarge
    ? 70
    : SCREEN_SIZES.mobileMedium
    ? 60
    : SCREEN_SIZES.mobileSmall
    ? 50
    : SCREEN_SIZES.mobileExtraSmall
    ? 40
    : 80,
};

export const BUTTON_SIZE = {
  bottomFullWidth: SCREEN_SIZES.mobileExtraLarge
    ? 56
    : SCREEN_SIZES.mobileLarge
    ? 52
    : SCREEN_SIZES.mobileMedium
    ? 48
    : SCREEN_SIZES.mobileSmall
    ? 44
    : SCREEN_SIZES.mobileExtraSmall
    ? 40
    : 56,
};
