import React from 'react';
import {
  Bold,
  CircleUL,
  NumOL,
  NumLI,
  RomanOL,
  SubTitle,
  TCWrapper,
  UBold,
} from './styles';

export default function TermsAndConditions() {
  return (
    <TCWrapper>
      <p>
        These terms and conditions set forth herein (the “SariClub Terms and
        Conditions”) constitute a valid and binding agreement (the “Agreement”)
        between You, as the “User”, and Growsari, Inc. and/or Growsari
        Enterprise, Inc. (together, “Growsari”). These SariClub Terms and
        Conditions are in addition to the Terms and Conditions of GROWSARI, and
        Software License Agreement governing the use of the SARICLUB.PH PROMOS
        application and your account.{' '}
      </p>
      <p>
        By clicking the “ACCEPT” button or using the application, you shall be
        deemed to have entered into and agreed to be bound by these SariClub
        Terms and Conditions.
      </p>
      <NumOL type="1">
        <NumLI>Use of the SARICLUB PROMOS</NumLI>
        <ul>
          <li>
            The SARICLUB PROMOS application (the “Application”) is a platform
            wherein the registered user is given the opportunity to avail of
            different promotions such as free samples, discounts and other
            benefits (Promos) from specified partner or participating stores
            nationwide.
          </li>
          <li>
            Enrolling for{' '}
            <a href="http://sariclub.ph/" target="_blank" rel="noreferrer">
              SariClub.ph{' '}
            </a>
            does not immediately guarantee Promos in your SariClub Promo Account
            (the “Account”). A wide variety of Promos are made available every
            week, which a registered user may choose from.
          </li>
          <li>
            You are responsible for all Transactions initiated by You on the
            Application.
          </li>
          <li>
            You agree to use, and shall use the Application only for lawful
            purposes and not to violate any applicable laws, rules or
            regulations;
          </li>
          <li>
            Growsari may, in its sole and absolute discretion, at any time and
            for any reason, including but not limited to cases where it
            reasonably believes that the transaction being conducted may be
            fraudulent, illegal or involves a criminal activity, suspend or
            terminate your use and the rights afforded to You hereunder.
          </li>
          <li>All Promos are redeemable for a specific period only.</li>
          <li>
            You are solely responsible for the safekeeping and use of your
            account and are liable for any losses, damages, costs, expenses or
            claims as a consequence of the unauthorized use of the same.
            Growsari shall not be liable for any claims, losses, damages, costs
            or expenses suffered or incurred by You or any third party as a
            result of the unauthorized use of the same.
          </li>
        </ul>

        {/* ----------------------------------------------------------------- */}

        <NumLI>General Terms of Service</NumLI>
        <ul>
          <li>
            When a Transaction Request is submitted by You, it shall be treated
            as your request for Growsari to process the Transaction on your
            behalf and your consent to the execution of the Transaction.{' '}
          </li>
          <li>
            Growsari reserves the right to modify, suspend, stop, cancel or
            terminate the Application or any of its Services immediately upon
            notice on the Application, at any time and from time to time, as may
            be allowed under applicable laws and regulations of the Philippines.
          </li>
          <li>
            You shall not use any device, software or routine to interfere or
            attempt to interfere with the proper working of the Services or any
            instruction being conducted through the Services. You shall be
            liable to Growsari and any third parties for any losses,
            liabilities, damages, costs and expenses suffered as a result of a
            violation of this clause, without prejudice to such actions and
            remedies that may be taken against You under applicable laws and
            regulations.
          </li>
          <li>
            Growsari reserves the right to examine, investigate and verify any
            suspicious activity on the Application or Services.
          </li>
          <li>
            Growsari shall not be liable for any delays or cancelation of
            Transactions due to the expiry of promos chosen by You, the
            unavailability of products or to comply with applicable laws, rules
            or regulations.
          </li>
          <li>
            Growsari, in its sole discretion, may refuse or cancel any
            Transaction Request if it reasonably believes You or any person is
            using the Service, or allowing it to be used, in breach of these
            SariClub Terms and Conditions or any applicable laws, rules or
            regulations.
          </li>
          <li>
            Growsari has the right, without prior notice to You, to
            automatically suspend, cancel, terminate or block a Transaction in
            case it has reason to believe that the Transaction or the account
            may be illegal or used for fraudulent or suspicious businesses or
            transactions, or by unauthorized persons. Growsari does not have the
            obligation to inform You prior to suspending or blocking the
            Transaction or the Account pursuant to this clause. You hereby
            acknowledge the authority of Growsari to suspend or block the
            Transaction and the Account itself. You hereby agree to hold
            Growsari, its shareholders, directors, officers, employees and
            agents, free and harmless against any and all consequences of such
            suspension, cancellation, termination or blocking, or any loss,
            damage, costs and expenses, which You may suffer or incur as a
            result thereof.
          </li>
          <li>
            In order to comply with our obligations under relevant laws and
            regulations, Growsari reserves the right to require further
            information or evidence relating to the personal information of our
            customers and to the purpose of any Transaction.
          </li>
          <li>
            By agreeing to these SariClub Terms and Conditions, You hereby give
            your express consent and authorize Growsari and/or any of its agents
            to disclose and share to third parties any and all information you
            have provided, including your personal information and sensitive
            personal information, as applicable, and consent to the use and
            processing of such information (including personal information and
            sensitive personal information) by Growsari and or any of its agents
            or third parties in connection any Transaction in the Application.
          </li>
          <li>
            Growsari verifies the Account Holder through his/her submitted
            mobile number upon registration. In situations wherein the mobile
            number has been compromised, become inactive or expired, or another
            person represents that the same is his/her mobile number, Growsari
            has the right to suspend or close the Account, upon strict
            validation and in accordance with the existing rules on the use of a
            mobile number.
          </li>
        </ul>

        {/* ----------------------------------------------------------------- */}

        <NumLI>Registration</NumLI>
        <ul>
          <li>
            Growsari is mandated to comply with any and all laws, rules or
            regulations issued by any governmental authority or instrumentality,
            including among others, the Department of Trade and Industry. Thus,
            you may be required to provide data (including personal information
            and sensitive personal information), prior to the opening of the
            account, after the opening and during the use of the application and
            at any time in an on-going basis. You agree that you will provide
            such information and/or documentation promptly to Growsari upon
            request. You shall be responsible to update your data to ensure its
            completeness and accuracy.
          </li>
        </ul>

        {/* ----------------------------------------------------------------- */}

        <NumLI>Obligations and Warranties of the Account Holder</NumLI>
        <ul>
          <li>
            The Account Holder agrees to these SariClub Terms and Conditions and
            further acknowledges that there is no agency, partnership, joint
            venture, employer-employee, licensor-licensee or
            franchisor-franchisee relationship between You, as the Account
            Holder, and Growsari due to the use of the Application.
          </li>
          <li>
            The Account Holder is more than eighteen (18) years of age and is
            able to fully and competently bind oneself to abide by the terms and
            agreement for the use of the Service.
          </li>
          <li>
            By using the said Services, the Account Holder declares and
            understands that he/she may be exposed to and receive content and
            information from a variety of sources through the Application and
            Growsari is not responsible for the accuracy, usefulness, safety,
            completeness or intellectual property rights relating to such
            content or information, including other content and information that
            may be linked to it. By agreeing to these SariClub Terms and
            Conditions, the Account Holder agrees to receive information and
            content from Growsari or third parties through the Application.{' '}
          </li>
          <li>
            The Account Holder understands that by using the Services, he/she
            declares and affirms that he/she takes sole responsibility for
            whatever consequences that may arise out such use. The Account
            Holder understands that Growsari shall not be liable for, and You
            hereby hold Growsari free from, any direct, incidental, special,
            consequential, indirect, or punitive damages whatsoever resulting
            from your use of, or your inability to use, the Services.{' '}
          </li>
          <li>
            The Account Holder understands and acknowledges to indemnify
            Growsari for any breach of its proprietary and other rights,
            including breach of these SariClub Terms and Conditions, which
            he/she may commit in the course of or arising out of your use of the
            Services.
          </li>
          <li>
            The Account Holder acknowledges and understands that there may be
            content, material or information presented to him/her as part of the
            Services, including but not limited to advertisements and sponsored
            content, material or information within the Services that may be
            protected by intellectual property rights which are owned by the
            respective sponsors, advertisers, or other persons or companies on
            their behalf. The Account Holder agrees that such advertisements and
            sponsored content, material or information may be placed on the
            Services and/or coupled to the User Content without prior notice and
            without need of explicit consent as the Account Holder now gives
            such consent. You hereby declare, acknowledge, and affirm personal
            and sole commitment and obligation to respect, uphold and honor any
            intellectual property rights which may cover such any content,
            material or information presented to you as part of the Services,
            including but not limited to advertisements and sponsored content,
            material or information within the Services.
          </li>
          <li>
            The Account Holder shall not alter, modify, or cause the alteration
            or modification of the Services. You further agree not to use the
            Services for any commercial use.
          </li>
          <li>
            The Account Holder agrees not to use any device or application to
            modify the proper working of the Services or any transaction being
            conducted using the Services. You agree that you will not engage in
            any activity that interferes with or disrupts the Services or the
            servers and networks which are connected to the Services.
          </li>
          <li>
            The Account Holder agrees not to use the Services for any purpose
            that is illegal, unlawful or prohibited by these SariClub Terms and
            Conditions and applicable laws and regulations, or to solicit the
            performance of any illegal activity or other activity which
            infringes on the rights of Growsari and its partners/affiliates or
            others, which includes but is not limited to selling, leasing or
            giving your Account to a third party and/or profiting from the goods
            or services by performing transactions using the Services for a
            third party.
          </li>
        </ul>

        {/* ----------------------------------------------------------------- */}

        <NumLI>Collection, Processing and Storage of Information</NumLI>
        <ul>
          <SubTitle>General Terms</SubTitle>
          <li>
            <UBold>You agree to and consent</UBold> to the collection, usage and
            storage of any information you provide, including personal
            information and sensitive personal information (“Information”). Said
            Information may be processed in order for Growsari and its
            affiliates, partners and agents to perform the Services it provides.
            Purposes may include but are not limited to improvement of services,
            marketing, advertisement and research. Without limiting the
            generality of the foregoing, the Information shall include, without
            limitation, the following types of information and data, in an
            aggregate (not user level) form: search requests, search results,
            patterns, data and suggestions based on user actions.
          </li>
          <CircleUL>
            <li>
              Notwithstanding the foregoing, you shall not provide or disclose,
              and the Information shall not include, any information or data
              that is personally identifiable to you. This clause will, however,
              not apply if you willingly and voluntarily consent to the contrary
              or if the Information is required to be provided or disclosed
              under Applicable Law and/or any government regulation. In any
              case, the Company shall not be held liable for the consequences of
              your actions.
            </li>
            <li>
              As a general rule, the Information will be treated as being
              confidential but non-proprietary unless you willingly consent to
              the waiver of its confidentiality. In the event that you have
              waived the confidentiality of your information, the same shall now
              be treated as non-confidential and non-proprietary and the Company
              therefore assumes no obligation to protect the same (other than
              personally identifiable information) from disclosure and will be
              free to reproduce, use, and distribute the Information to others
              without restriction. We will also be free to use any ideas,
              concepts, know- how or techniques contained in the Information for
              any purpose or whatsoever including, without limitation,
              developing, manufacturing and marketing products and services
              incorporating such Information.
            </li>
            <li>
              You agree and hereby undertake the sole responsibility and hold
              the Company free of liability to you or to any third party, for
              any data or information that you provide, transmit, or submit
              while using the Services. You agree and hereby undertake sole
              responsibility for the consequences of your actions and
              interactions using the Services, and hereby stipulate admission of
              liability to the Company for whatever loss or damage the Company
              may suffer as a consequence of your use or misuse of the Services.
            </li>
          </CircleUL>
          <SubTitle>Data Consent / Data Sharing </SubTitle>
          <li>
            <UBold>You hereby grant consent</UBold> on the general use and
            sharing of Information (including personal information and sensitive
            personal information) obtained from you in the course of any
            transaction, between you and Growsari, its parent and affiliates and
            their respective representatives and agents and/or any of Growsari’s
            accredited third parties. You acknowledge and agree that any member
            of Growsari may share these data between them and that such data may
            constitute your personal or sensitive personal information (which
            includes, without limitation, your name, address, contact details
            [mobile number and email]), as well as information relating to your
            transactions and communications with any member of Growsari.
          </li>
          <li>
            Said data may be collected, processed, stored, updated, or disclosed
            by any member of the Growsari by physical, electronic or automated
            means and shared between and among them or to third parties: (i) for
            regular business use such as marketing initiatives for sari-sari
            stores and Growsari, data collection and analytics; (ii) for
            legitimate purposes including but not limited to risk management,
            know your customer (KYC) checks, prevention and detection of fraud
            or crime, cross-selling, profiling; (iii) to provide the Services to
            you or implement transactions which you request, allow, or
            authorize; and (iv) to comply with Growsari’s internal policies and
            its reporting obligations to comply with: (a) Applicable Law and
            internal policies or procedures; or (b) any demand and/or requests
            from any Government Authority for purposes of reporting, regulatory
            trade reporting, disclosure or other obligations under Applicable
            Law.
          </li>
          <li>
            Your consent authorizes members of Growsari to process, collect,
            use, store, share or disclose your Information between them and to
            other members, to government authority, and to any third parties,
            who acquires the rights and obligations of Growsari; who processes
            information, transactions, services or accounts on behalf of
            Growsari; or who requires the information for market research,
            product and business analysis, or for marketing or advertising
            activities by Growsari, as may be necessary.
          </li>
          <li>
            Your information may continue to be collected, stored, processed
            and/or shared between and among members of Growsari for five (5)
            years from the conclusion of your Transactions with them and any of
            their members or until the expiration of the retention limits set by
            Applicable Law, whichever comes later. Growsari shall immediately
            destroy or dispose in a secure manner any Information the retention
            of which is no longer allowed under the said law.
          </li>
          <li>
            You understand that you have the right to access, update, or correct
            certain personal information, or withdraw consent to the use of any
            information at any given time by communicating with the Growsari
            Data Protection Officer at Level 10-1 Fort Legend Tower, 3rd Avenue
            corner 31st Street, Bonifacio Global City, Taguig City, 1632 or via
            email DPO_GSI@growsari.com or DPO_GSE@growsari.com. You recognize
            that you may file complaints with and/or seek assistance from the
            National Privacy Commission.
          </li>
        </ul>

        {/* ----------------------------------------------------------------- */}

        <NumLI>Amendments</NumLI>
        <ul>
          <li>
            The Company can modify or amend the terms of SARICLUB by posting a
            copy of the modified or amended license on the Company’s website at{' '}
            <a href="https://growsari.com/" target="_blank" rel="noreferrer">
              www.growsari.com
            </a>{' '}
            or by updating the terms and conditions uploaded in the application.
            You will be deemed to have agreed to any such modification or
            amendment either by your continued use of the app or the wallet
            after the amendment or by ticking I agree to the terms and
            conditions of use of the Growsari Mobile Application.
          </li>
          <li>
            The Account Holder agrees that he/she are responsible to check for
            update/s to the SARICLUB PROMO before use. You understand that
            certain functions may not be available or disabled if you do not
            update the application or consent to the new terms and conditions
            during your use of the app.
          </li>
        </ul>

        {/* ----------------------------------------------------------------- */}

        <NumLI>Disclaimer of Warranties</NumLI>
        <p>
          You acknowledge and agree that the application is provided on an “as
          is” and “as available” basis, and that your use of or reliance upon
          the Application and by any third-party content and services accessed
          thereby is at your sole risk and discretion. The Company and its
          affiliates, partners, suppliers and licensors hereby disclaim any and
          all representations, warranties, and guarantees regarding the
          Application and third-party content and services, whether express,
          implied or statutory, and including, without limitation, the implied
          warranties of merchantability, fitness for a particular purpose, and
          non-infringement.
        </p>
        <p>
          Furthermore, the Company and its affiliates, partners, suppliers, and
          licensors make no warranty that{' '}
        </p>
        <RomanOL type="I">
          <li>
            The Application or third-party content and services will meet your
            requirements;
          </li>
          <li>
            The Application or third-party content and services will be
            uninterrupted, accurate, reliable, timely, secure or error-free;
          </li>
          <li>
            The quality of any products, services, information or any other
            material accessed or obtained by You through the Application will be
            represented or meet your expectations; or{' '}
          </li>
          <li>
            Any errors in the Application or third-party content and services
            will be corrected, no advice or information, whether oral or
            written, obtained by you from the Company or from the Application
            shall create any representation, warranty or guaranty.{' '}
          </li>
        </RomanOL>

        {/* ----------------------------------------------------------------- */}

        <NumLI>LIMITATION OF LIABILITY</NumLI>
        <p>
          Under no circumstances shall the Company, or its affiliates, partners,
          suppliers, or licensors be liable for any indirect, incidental,
          consequential, special, punitive or exemplary damages (including but
          not limited to lost profits, costs of delay, failure of delivery,
          business interruption, data loss, damage of disclosure, or liabilities
          to third parties) arising out of or in connection with your access or
          use of or inability to access or use the Application or any
          third-party content and services, whether or not the damages were
          foreseeable and whether or not the company was advised of the
          possibility of such damages. Without limiting the generality of the
          foregoing, the Company’s aggregate liability to You (whether under
          contract, tort, statute or otherwise) shall not exceed the amount of
          Php3,000.00. The foregoing limitations will apply even if the above
          stated remedy fails of its essential purpose.
        </p>

        {/* ----------------------------------------------------------------- */}

        <NumLI>Notices</NumLI>
        <ul>
          <li>
            Where demand or notice is required to be given under this Agreement,
            notice sent to the parties through any print or electronic
            communication as may be deemed appropriate by Growsari or at the
            address or email address specified upon registration or use shall be
            sufficient compliance with the requirement of notice or demand.
          </li>
          <li>
            Demand or notice from you shall only be given through writing at our
            designated address or email address and shall be deemed to have been
            received only upon confirmation.
          </li>
        </ul>

        {/* ----------------------------------------------------------------- */}

        <NumLI>Miscellaneous</NumLI>
        <ul>
          <li>
            GROWSARI TERMS AND CONDITIONS. The Account Holder acknowledges that
            the use of these Services is also covered by the other Terms and
            Conditions of the Growsari App, as maybe applicable.
          </li>
          <li>
            Governing Law. This Agreement shall be deemed to take place in the
            Philippines and shall be governed and construed in accordance with
            the laws of the said country excluding its conflict of law
            principles. Any disputes arising from this Agreement shall be
            adjudicated in the <Bold>Courts of Pasig City or Taguig City,</Bold>{' '}
            to the exclusion of all other venues. This shall not be governed by
            the United Nations Convention on Contracts for the International
            Sale of Goods, the application of which is expressly excluded.
          </li>
          <li>
            Severability. If any provision of this is held to be invalid or
            unenforceable with respect to a party, the remainder, or the
            application of such provision to persons other than those to whom it
            is held invalid or unenforceable shall not be affected and each
            remaining provision of this Agreement shall be valid and enforceable
            to the fullest extent as permitted by law.
          </li>
          <li>
            Waiver. Except as provided herein, the failure to exercise a right
            or require performance of an obligation under this Agreement shall
            not affect a party’s ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute waiver of any subsequent breach.
          </li>
          <li>
            Assignment. You shall not assign this license or any rights or
            obligations herein without the prior written consent of the Company
            and any attempted assignment in contravention of this provision
            shall be null and void and of no force and effect.
          </li>
          <li>
            Subcontracting: Growsari reserves its right to delegate or
            sub-contract the performance of any of our functions or obligations
            in connection with the Services to any service provider,
            subcontractor and/or agent on such terms as it may deem appropriate
            and as permitted under relevant laws and regulations.
          </li>
          <li>
            Entire Agreement. This Agreement including the documents
            incorporated herein by reference constitute the entire agreement
            with respect to the use of the Services and supersedes all prior or
            contemporaneous understandings regarding such matter.
          </li>
        </ul>
      </NumOL>
    </TCWrapper>
  );
}
