import {
  Button
} from 'antd';
import styled, {
  css
} from 'styled-components';

import {
  COLORS,
  FONTS,
  SIZES,
  BUTTON_SIZE
} from '../../app-theme/constants';

const commonFlex = css `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;



export const StyledFBLoginButton = styled( Button )`
height: ${BUTTON_SIZE.bottomFullWidth}px;
border-radius: 12px;
background: ${COLORS.fbBlue};
color:white;
border:0px transparent;  
text-align: center;
margin:5px;
display: inline-block;
font-size: ${SIZES.font}px;
font-weight: ${FONTS.bold};

&:hover{
    background: ${COLORS.fbBlue};
    opacity: 0.95;
}

&.ant-btn.ant-btn-primary {
  ${commonFlex}
  background: ${COLORS.fbBlue};
  border-color: ${COLORS.fbBlue};
  color: ${COLORS.white};
}

&.ant-btn-primary:hover,
&.ant-btn-primary:focus {
  opacity: 0.95;
}

&.ant-btn-default {
  ${commonFlex}
  background: ${COLORS.fbBlue};
  border: 1.4px solid ${COLORS.fbBlue};
  color: ${COLORS.white};
}

&.ant-btn-default:hover,
&.ant-btn-default:focus {
  background: rgba(62, 180, 166, 0.4);
}

&.ant-btn-link {
  ${commonFlex}
  color: ${COLORS.disabled};
  font-size: ${SIZES.small}px;
  font-weight: ${FONTS.medium};
  height: 100%;
  padding: 0;
}
`;

export const StyledFBIcon = styled.img`
    width: 15px;
    height: 15px;
    vertical-align: none;
    flex: none;
`;


export const StyledButton = styled( Button )`
  padding: 16px;
  height: ${BUTTON_SIZE.bottomFullWidth}px;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: ${SIZES.font}px;
  font-weight: ${FONTS.bold};

  &.ant-btn.ant-btn-primary {
    ${commonFlex}
    background: ${COLORS.accent};
    border-color: ${COLORS.accent};
    color: ${COLORS.white};
  }

  &.ant-btn-primary:hover,
  &.ant-btn-primary:focus {
    opacity: 0.95;
  }

  &.ant-btn-default {
    ${commonFlex}
    background: ${COLORS.white};
    border: 1.4px solid ${COLORS.accent};
    color: ${COLORS.accent};
  }

  &.ant-btn-default:hover,
  &.ant-btn-default:focus {
    background: rgba(62, 180, 166, 0.4);
  }

  &.ant-btn-link {
    ${commonFlex}
    color: ${COLORS.disabled};
    font-size: ${SIZES.small}px;
    font-weight: ${FONTS.medium};
    height: 100%;
    padding: 0;
  }
`;