import React from 'react';
import { StyledButton } from './styles';

export default function Button({
  text,
  icon,
  size,
  shape,
  type,
  color,
  disabled,
  handleClick,
  iconPosition,
}) {
  return (
    <StyledButton
      type={type}
      icon={icon}
      size={size}
      shape={shape}
      className={`${color} ${iconPosition === 'left' ? 'left' : 'right'}`}
      block
      disabled={disabled}
      onClick={handleClick}
    >
      {text}
    </StyledButton>
  );
}
