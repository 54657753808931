import styled, { css } from 'styled-components';
import OtpInput from 'react-otp-input';

import { COLORS, FONTS, SIZES, LINE_HEIGHT } from '../../app-theme/constants';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  ${commonFlex}
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;

  div {
    font-family: 'Poppins';
    font-weight: ${FONTS.semiBold};
    font-size: ${SIZES.small}px !important;
    line-height: ${LINE_HEIGHT.level2}px;
    color: ${COLORS.disabled} !important;
    margin-top: 3px;

    button {
      background: transparent !important;
      color: ${COLORS.accent} !important;
      cursor: pointer;
      font-size: ${SIZES.small}px !important;
    }
  }
`;

export const StyledOTPInput = styled(OtpInput)``;
