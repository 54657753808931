import styled, { css } from 'styled-components';

import { COLORS, FONTS, SIZES, MISCELLANEOUS } from '../../app-theme/constants';

const commonDimensions = css`
  height: ${MISCELLANEOUS.numPadSize}px;
  width: ${MISCELLANEOUS.numPadSize}px !important;
`;

export const StyledButton = styled.div`
  ${commonDimensions}
  color: ${COLORS.secondary} !important;
  background: ${COLORS.keypad};
  font-size: ${SIZES.extraLarge}px;
  font-weight: ${FONTS.bold};
  border: none;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;

  :hover,
  :focus {
    opacity: 0.8;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 14px;
`;

export const PadWrapper = styled.div`
  margin: 24px 0 0 0;
`;

export const Column = styled.div`
  ${commonDimensions}
`;

export const StyledBackspace = styled.div`
  background: transparent;
  border: none;
  height: 100%;
  width: 100% !important;
  box-shadow: none;
  display: grid;
  place-items: center;
  cursor: pointer;

  &.ant-btn-primary:hover,
  &.ant-btn-primary:focus {
    background: transparent;
    opacity: 0.95;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  svg {
    font-size: ${SIZES.backspace}px;
    fill: ${COLORS.tertiary};
  }
`;
