import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Dropdown, Loading, GradientHeader } from '../../components';
import { BodyWrapper } from './styles';
import {
  getBarangayList,
  getCityList,
  getProvinceList,
} from '../../utils/getLocalityList';
import axios from 'axios';
import { LOCALITIES_URI } from '../../config';

import {
  AnalyticsEventsTypes,
  ButtonTypes,
  PageLabels,
} from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';

const StoreDetails = () => {
  const { fireEvent, fireButtonClickEvent, fireViewScreenEvent } = useAnalytics(
    {
      pageLabel: PageLabels.STORE_DETAILS,
    }
  );

  const navigate = useNavigate();
  const { state } = useLocation();
  const account_id = state && state.account_id;
  const promo_code = state && state.promo_code;

  const [loading, setLoading] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();

  const [barangayOptions, setBarangayOptions] = useState([]);
  const [selectedBarangay, setSelectedBarangay] = useState();

  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState();

  const setLocalities = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(LOCALITIES_URI, {
        query: `query {
            localityData {
              barangay_list {
                id
                locality
                city
                province
                region
                country
                is_deleted
                code
                updated_at
                is_serviceable
              }
              city_list {
                city
                province
                region
                country
                is_serviceable
              }
            }
          }`,
      });

      const localities = data.data.localityData;
      setProvinceOptions(getProvinceList(localities));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setProvinceOptions(getProvinceList({}));
    }
  }, []);

  const handleSearch = () => {
    fireButtonClickEvent({
      button_name: ButtonTypes.SEARCH,
      screen_name: PageLabels.STORE_DETAILS,
      account_id: account_id,
    });

    navigate('/store-results', {
      state: {
        location: `${selectedBarangay}, ${selectedCity}`,
        barangay: selectedBarangay,
        city: selectedCity,
        province: selectedProvince,
        account_id: account_id,
        promo_code: promo_code,
      },
    });
  };

  useEffect(() => {
    setLocalities();
  }, [setLocalities]);

  useEffect(() => {
    fireViewScreenEvent({
      screen_name: PageLabels.STORE_DETAILS,
      previous_screen_name: sessionStorage.getItem('PreviousPage'),
      account_id: account_id,
    });
  }, []);

  const handleSelectProvince = (province) => {
    fireEvent(AnalyticsEventsTypes.SELECT_DROPDOWN_OPTION, {
      choice_selected: province,
      screen_name: PageLabels.STORE_DETAILS,
    });

    setSelectedProvince(province);
    const cities = getCityList(province);
    setCityOptions(cities);
  };

  const handleSelectCity = (city) => {
    fireEvent(AnalyticsEventsTypes.SELECT_DROPDOWN_OPTION, {
      choice_selected: city,
      screen_name: PageLabels.STORE_DETAILS,
    });

    setSelectedCity(city);
    const barangays = getBarangayList(city);
    setBarangayOptions(barangays);
  };

  const handleSelectBarangay = (barangay) => {
    fireEvent(AnalyticsEventsTypes.SELECT_DROPDOWN_OPTION, {
      choice_selected: barangay,
      screen_name: PageLabels.STORE_DETAILS,
    });

    setSelectedBarangay(barangay);
  };

  const body = (
    <>
      {loading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <Dropdown
            label="Province"
            placeholder="Select province"
            options={provinceOptions}
            onSelect={handleSelectProvince}
            loading={loading}
          />
          <Dropdown
            label="City"
            placeholder="Select city"
            disabled={!cityOptions.length}
            options={cityOptions}
            onSelect={handleSelectCity}
            loading={loading}
          />
          <Dropdown
            label="Barangay"
            placeholder="Select barangay"
            disabled={!barangayOptions.length}
            options={barangayOptions}
            onSelect={handleSelectBarangay}
            loading={loading}
          />
          <Button
            type="primary"
            text="Search"
            icon={<ArrowRightOutlined />}
            handleClick={handleSearch}
            disabled={!selectedBarangay}
          />
        </BodyWrapper>
      )}
    </>
  );

  return (
    <GradientHeader
      heading="Saan pwede mag-redeem?"
      description="Ilagay ang iyong location para makita ang participating stores sa iyong lugar!"
      body={body}
    />
  );
};

export default StoreDetails;
