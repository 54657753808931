import styled, { css } from 'styled-components';

import { COLORS, DIMENSIONS } from '../../../app-theme/constants';

let viewportHeight = window.innerHeight;

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

const gradientBackground = css`
  background: radial-gradient(rgb(12, 142, 127) 0%, rgb(62, 180, 166) 100%);
  background-blend-mode: overlay, normal;
`;

const defaultShadow = css`
  filter: drop-shadow(0px 8px 16px rgba(38, 35, 56, 0.1));
`;

export const DesktopContainer = styled.div`
  height: ${viewportHeight ? `${viewportHeight}px` : '100vh'};
  background: ${COLORS.background};
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.maxWidth}px) {
    display: flex;
    height: 100%;
  }

  @media only screen and (min-width: ${DIMENSIONS.maxWidth + 1}px) {
    display: grid;
    place-items: center;
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  max-width: ${DIMENSIONS.maxWidth}px;

  ${gradientBackground}
  ${defaultShadow}

  @media only screen and (max-width: ${DIMENSIONS.maxWidth}px) {
    border-radius: 0;
  }

  @media only screen and (min-width: ${DIMENSIONS.maxWidth + 1}px) {
    border-radius: 16px;
  }
`;

export const TopContainer = styled.div`
  ${commonFlex}
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex: 1;
  gap: 16px;
  position: relative;
`;

export const BottomContainer = styled.div`
  ${commonFlex}
  position: relative;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: ${COLORS.white};
  border-radius: 24px 24px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.maxWidth}px) {
    border-radius: 24px 24px 0 0;
  }

  @media only screen and (min-width: ${DIMENSIONS.maxWidth + 1}px) {
    border-radius: 24px 24px 16px 16px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Logo = styled.div`
  ${commonFlex}
  align-items: center;
  gap: 36px;
  padding: 24px;
`;

export const Promo = styled.div`
  ${commonFlex}
  gap: 8px;
  margin-top: 7px;
  width: 100%;
  padding: 32px;
`;

export const LandingImage = styled.div`
  position: absolute;
  bottom: 0;

  .ant-image-img {
    width: 90%;
  }

  @media screen and (max-width: 320px) and (max-height: 598px) {
    .ant-image-img {
      display: none;
    }
  }
`;
