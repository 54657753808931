import React from 'react';
import { Text } from '../../components';

import { StyledInput, StyledInputContainer } from './styles';

export default function Input({
  label,
  icon,
  placeholder,
  autoFocus,
  handleUserInput,
  defaultValue,
  maxLength,
  rules,
  type,
  pattern,
  onClick,
}) {
  //disable up, down
  window.addEventListener(
    'keydown',
    function (e) {
      if ([38, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault();
      }
    },
    false
  );

  return (
    <StyledInputContainer>
      {label && (
        <Text
          text={label}
          size="small"
          color="secondary"
          fontWeight="semiBold"
          lineHeight="level2"
        />
      )}
      <StyledInput
        prefix={icon}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={(e) => handleUserInput(e)}
        value={defaultValue}
        maxLength={maxLength}
        rules={rules}
        type={type}
        pattern={pattern}
        onClick={onClick}
      />
    </StyledInputContainer>
  );
}
