import { Input } from 'antd';
import styled from 'styled-components';

import { COLORS, SIZES, FONTS, LINE_HEIGHT } from '../../app-theme/constants';

export const StyledInput = styled(Input)`
  padding: 12px 16px;
  font-weight: ${FONTS.regular};
  font-size: ${SIZES.font}px;
  color: ${COLORS.primary};
  line-height: ${LINE_HEIGHT.level3}px;
  border-radius: 16px;

  &.ant-input::placeholder {
    color: ${COLORS.tertiary};
    font-size: ${SIZES.font}px;
  }
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
