import styled, { css } from 'styled-components';

const commonFlex = css`
  display: flex;
  flex-direction: column;
`;

export const BodyWrapper = styled.div`
  ${commonFlex}
  height: 100%;
  gap: 24px;

  .ant-btn.ant-btn-primary {
    gap: 18px;
  }
`;

export const ImageWrapper = styled.div`
  margin: 40px;

  .ant-image {
    width: 100%;
  }
`;
