import { useCallback, useContext, useEffect } from 'react';
import { ReactSession } from 'react-client-session';
import { AnalyticsEventsTypes } from '../constants';
import { UserContext } from '../contexts';
import { FullStory } from '@fullstory/browser';

const getDefaultParameters = (userData, pageLabel) => ({
  timestamp: Date.now().toString(),
  screen_name: pageLabel,
  ...(userData && {
    suki_id: userData.id,
    suki_type: userData.registration_type,
  }),
});

const useAnalytics = ({ firePageEventsOnLoad = true, pageLabel }) => {
  const { userData } = useContext(UserContext);
  const fullStorySession = ReactSession.get('fullStorySession');

  const fireEvent = useCallback(
    async (eventType, eventParams = {}, fireCount = 0) => {
      const defaultParams = getDefaultParameters(userData, pageLabel);
      const params = {
        ...defaultParams,
        ...eventParams,
      };
      if (window && window.gtag) {
        window.gtag('event', eventType, params);
        if (fullStorySession) {
          FullStory('trackEvent', { name: eventType, properties: params });
        }
      } else if (fireCount < 5) {
        setTimeout(fireEvent, 250, eventType, eventParams, fireCount + 1);
      } else {
        // Failed to send analytics event
      }
    },
    [pageLabel, userData]
  );

  const firePageViewEvent = useCallback(() => {
    fireEvent('page_view', {
      page_title: pageLabel,
      page_path: window.location.pathname + window.location.search,
    });

    const prevPage = sessionStorage.getItem('CurrentPage');

    if (pageLabel !== prevPage) {
      sessionStorage.setItem('CurrentPage', pageLabel);
      sessionStorage.setItem('PreviousPage', prevPage);
    }
  }, [fireEvent]);

  const fireViewScreenEvent = useCallback(
    (parameters) => {
      fireEvent(AnalyticsEventsTypes.VIEW_SCREEN, parameters);
    },
    [fireEvent]
  );

  const fireScrollScreenEvent = useCallback(
    (parameters) => {
      fireEvent(AnalyticsEventsTypes.SCROLL_SCREEN, parameters);
    },
    [fireEvent]
  );

  const fireButtonClickEvent = useCallback(
    (parameters) => {
      fireEvent(AnalyticsEventsTypes.CLICK_BUTTON, parameters);
    },
    [fireEvent]
  );

  const fireCheckBoxClickEvent = useCallback(
    (parameters) => {
      fireEvent(AnalyticsEventsTypes.CLICK_CHECKBOX, parameters);
    },
    [fireEvent]
  );

  const fireViewPopupEvent = useCallback(
    (parameters) => {
      fireEvent(AnalyticsEventsTypes.VIEW_POPUP, parameters);
    },
    [fireEvent]
  );

  useEffect(() => {
    if (firePageEventsOnLoad) {
      firePageViewEvent();
    }
  }, [firePageEventsOnLoad, pageLabel, firePageViewEvent]);

  return {
    fireEvent,
    firePageViewEvent,
    fireViewScreenEvent,
    fireScrollScreenEvent,
    fireButtonClickEvent,
    fireCheckBoxClickEvent,
    fireViewPopupEvent,
    pageLabel,
  };
};

export default useAnalytics;
