import arrow from '../../assets/arrow-right.gif';
import bannerIcon from '../../assets/banner-icon.png';
import emptyState from '../../assets/empty-state.png';
import fbIcon from '../../assets/fbIcon.svg';
import globalLoader from '../../assets/global-loader.gif';
import growsariLogo from '../../assets/growsari-logo.svg';
import howTo1 from '../../assets/how-to-1.png';
import howTo2 from '../../assets/how-to-2.png';
import howTo3 from '../../assets/how-to-3.png';
import howTo3V2 from '../../assets/how-to-3-v2.png';
import howTo4 from '../../assets/how-to-4.png';
import howTo4V2 from '../../assets/how-to-4-v2.png';
import landing from '../../assets/landing.png';
import landingV2 from '../../assets/landing-v2.png';
import landingBanner from '../../assets/landing-banner.png';
import location from '../../assets/location.svg';
import logo512 from '../../assets/logo512.png';
import logoAllTeal from '../../assets/logo-all-teal.png';
import logoTagline from '../../assets/logo-tagline.png';
import logoTeal from '../../assets/logo-teal.png';
import logoWhite from '../../assets/logo-white.png';
import sample from '../../assets/sample-product.png';
import toastError from '../../assets/toast-error.svg';
import FBLinkLandingLogo from '../../assets/FBLinkLandingLogo.png';
import FBLoginSuccess from '../../assets/FBLoginSuccess.png';
import FBLoginFailed from '../../assets/FBLoginFailed.png';

export default {
  arrow,
  bannerIcon,
  emptyState,
  fbIcon,
  globalLoader,
  growsariLogo,
  howTo1,
  howTo2,
  howTo3,
  howTo3V2,
  howTo4,
  howTo4V2,
  landing,
  landingV2,
  landingBanner,
  location,
  logo512,
  logoAllTeal,
  logoTagline,
  logoTeal,
  logoWhite,
  sample,
  toastError,
  FBLinkLandingLogo,
  FBLoginSuccess,
  FBLoginFailed,
};
